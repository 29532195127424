import PropTypes from 'prop-types';
import React from 'react';
import AnimatedElementBase from './base/AnimatedElementBase';
/**
 * 
 * Component view that animates.
 * 
 */
export default class AnimatedView extends React.PureComponent {
  static propTypes = {
    /** A StyleSheet object, or array of StyleSheet objects to apply to the container */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    /** An object containing keyframe animations, to from, to enter exit. See example.  */
    animation: PropTypes.object,
    /** Animation in milliseconds (numeric) or other (string)  */
    duration: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    /** Specifies the speed curve of the animation  */
    timingFunction: PropTypes.oneOf(["linear", "ease", "ease-in", "ease-out", "ease-in-"]),
    /** The number of times an animation should be played.  */
    iterationCount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf(["infinite"])
    ]),
    /** Style for the element when the animation is not playing (before it starts, after it ends, or both).  */
    fillMode: PropTypes.oneOf(["forwards", "backwards", "both"]),
    /** Content to be placed into the container */
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  };
  static defaultProps = {
    duration: 250,
    timingFunction: "ease-in",
    fillMode: "forwards"
  };
  
  render(){
    return <AnimatedElementBase type='view' {...this.props} />
  }
}