import React from "react";
import R14 from "../../R14";
import StyleSheet from "../StyleSheet";
import View from "../View";

export default class ImageBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.htmlRef = this.props.htmlRef || React.createRef();
    this.handleLayout = this.handleLayout.bind(this);
    this.state = {
      source: this._initSource(),
      load: this.props.lazyLoad ? false : true,
    };
    this._intersectionObserver = null;
    if (this.props.lazyLoad) this.createIntersectionObserver();
  }
  componentDidMount() {
    if (this.props.auth && this.state.load) this._fetchObjectUrl();
  }
  componentWillUnmount() {
    if (this.props.auth) this._removeObjectUrl();
  }
  initIntersectionObserver(ref) {
    if (!this.props.lazyLoad || !ref) return;
    this._intersectionObserver.observe(ref);
  }
  createIntersectionObserver(ref) {
    if (!this.props.lazyLoad) return;
    this._intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (!entries || !entries.length) return;
        let entry = entries[0];
        if (entry.isIntersecting) {
          this._fetchObjectUrl();
          this.setState({ load: true });
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
  }
  _removeObjectUrl() {
    if (this.props.auth && this.props.source && this.props.source.uri)
      R14.api.removeObjectUrl(this.props.source.uri);
  }
  async _fetchObjectUrl() {
    if (this.props.auth && this.props.source && this.props.source.uri) {
      let url = await R14.api.fetchObjectUrl(this.props.source.uri);
      this.setState({
        source: { uri: url },
      });
    }
  }
  static async getSize(uri) {
    if (!uri) throw new Error("No image uri found.");
    return new Promise((resolve, reject) => {
      var image = new Image();
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = () => {
        reject(`Failed to load image: ${uri}`);
      };
      image.src = uri;
    });
  }
  _initSource() {
    if (this.props.auth) {
      return null;
    }
    return this.props.source || null;
  }
  handleLayout(layout) {
    // Call onLayout for the view
    this.props.onLayout && this.props.onLayout(layout);
  }
  defaultSource(uri) {
    // Not implemented.
    return null;
  }
  createDynamicStyles(src) {
    let imgStyles = {
      backgroundImage:
        this.state.load && src
          ? `url(${src})`
          : "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC)",
      backgroundRepeat: "no-repeat",
      height: "height" in this.props ? this.props.height : 0,
      width: "width" in this.props ? this.props.width : 0,
    };
    switch (this.props.resizeMode) {
      case "cover":
      case "contain":
        imgStyles.backgroundSize = this.props.resizeMode;
        break;
      case "repeat":
        imgStyles.backgroundRepeat = "repeat";
        break;
      default:
        if (this.props.resizeMode)
          throw new Error(
            `Image Error: Resize mode '${this.props.resizeMode}'`
          );
    }
    return StyleSheet.create({
      img: imgStyles,
    });
  }
  render() {
    //if (!this.state.source) return null;
    let source = this.state.source;
    let src = null;
    if (source) {
      switch (typeof source) {
        case "object":
          let imageKey = source.key || null;
          src = source.uri || source.default || null;
          if (imageKey) {
            // Get image path from config
            let config = R14.getInstance().config;
            if (
              (!config.storage && !config.storage.url) ||
              !config.storage.url.image
            )
              throw new Error(
                "Error displaying image by key. Image routepath not defined in config"
              );
            src = `${config.storage.url.image}/${imageKey}`;
          }
          break;
        case "string":
          src = source;
          break;
      }
    }

    let dynamicStyles = this.createDynamicStyles(src);
    return (
      <View
        tooltip={this.props.tooltip || null}
        style={[dynamicStyles.img, this.props.style]}
        htmlTag='span'
        onLayout={this.props.onLayout ? this.handleLayout : null}
        htmlRef={(ref) => {
          this.htmlRef = ref;
          this.initIntersectionObserver(ref);
        }}
      />
    );
  }
  styles() {
    return StyleSheet.create({});
  }
}

const styles = StyleSheet.create({});

// export default class ImageBase extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.handleLayout = this.handleLayout.bind(this);
//     this.handleImgLayout = this.handleImgLayout.bind(this);
//     this.state = {
//       dimensions: {
//         image: {
//           height: this.props.imageHeight || null,
//           height: this.props.imageWidth || null,
//         },
//         view: {
//           height: this.props.height || null,
//           height: this.props.width || null,
//         },
//       },
//     };
//   }
// static async getSize(uri) {
//     return new Promise((resolve, reject) => {
//       console.log("Look for image", uri);
//       var image = new Image();
//       image.onload = () => {
//         resolve({ width: this.width, height: this.height });
//       };
//       image.onerror = () => {
//         reject(`Failed to load image: ${uri}`);
//       };
//       image.src = uri;
//     });
//   }
//   handleLayout({ nativeEvent }) {
//     // console.log('HANDLE LAYOUT', nativeEvent);
//     // if (!this.props.autoSize) return;
//     // let viewDimension = this.getViewDimensions();
//     // if (!viewDimension.height && !viewDimension.width) {
//     //   console.error("Auto size requires both height and width view dimensions");
//     //   return;
//     // }
//   }
//   handleImgLayout({ nativeEvent }) {
//     // console.log('HANDLE IMAGE LAYOUT', nativeEvent);
//     // if (!this.props.autoSize) return;
//     // let viewDimension = this.getViewDimensions();
//     // if (!viewDimension.height && !viewDimension.width) {
//     //   console.error("Auto size requires both height and width view dimensions");
//     //   return;
//     // }
//   }
//   autoSize(viewDimensions, imgDimension) {
//     if (!this.props.autoSize) return false;
//     let dimensions = this.state.dimensions;
//   }
//   render() {
//     /** @todo move this out to image and get native working the same */
//     let src = this.props.source;
//     let hasSize = true;
//     let viewDimension = this.state.dimensions.view;
//     if (this.props.source && typeof this.props.source === "object") {
//       let imageKey = this.props.source.key || null;
//       src = this.props.source.uri || null;

//       if (imageKey) {
//         // Get image path from config
//         let config = R14.getInstance().config;
//         if (
//           (!config.storage && !config.storage.url) ||
//           !config.storage.url.image
//         )
//           throw new Error(
//             "Error displaying image by key. Image routepath not defined in config"
//           );
//         src = `${config.storage.url.image}/${imageKey}`;
//       }
//       hasSize = false;
//     }
//     let viewStyles = {
//       display: "inline-block",
//       backgroundImage: `url(${src})`,
//       backgroundRepeat: "no-repeat",
//       overflow: "hidden",
//       position: "relative",
//       height:
//         "height" in this.props ? viewDimension.height : hasSize ? "auto" : 0,
//       width: "width" in this.props ? viewDimension.width : hasSize ? "auto" : 0,
//     };
//     let imgStyle = {};
//     if (this.props.autoSize) {
//       imgStyle = {
//         width: "100%",
//         height: "auto",
//       };
//       viewStyles.backgroundImage = null;
//     } else {
//       imgStyle = {
//         visibility: "hidden",
//         position: "absolute",
//       };
//     }

//     switch (this.props.resizeMode) {
//       case "cover":
//       case "contain":
//         viewStyles.backgroundSize = this.props.resizeMode;
//         break;
//       case "repeat":
//         viewStyles.backgroundRepeat = "repeat";
//         break;
//       default:
//         if (this.props.resizeMode)
//           throw new Error(
//             `Image Error: Resize mode '${this.props.resizeMode}'`
//           );
//     }

//     let dynamicStyles = StyleSheet.create({
//       view: viewStyles,
//       img: imgStyle,
//     });

//     let viewProps = {};
//     let imgViewProps = {};
//     // if (this.props.autoSize) {
//     //   viewProps.onLayout = this.handleLayout;
//     //   imgViewProps.onLayout = this.handleImgLayout;
//     // }
//   console.log('dynamic styles', dynamicStyles.view);
//     return (
//       <View style={[dynamicStyles.view, this.props.style]} {...viewProps}>
//         <View style={[dynamicStyles.imgWrapperStyle]} {...imgViewProps}>
//           <img
//             src={src}
//             className={StyleSheet.className([dynamicStyles.img])}
//           />
//         </View>
//       </View>
//     );
//   }
//   styles() {
//     return StyleSheet.create({});
//   }
// }

// const styles = StyleSheet.create({});
