import React from "react";
import PropTypes from "prop-types";
import R14, {
  TextInput,
  StyleSheet,
  Colors,
  AnimatedView,
  View,
  Text,
  Surface,
  Chip,
  ChipGroup,
  Switch,
} from "../core";

import SideSheet from "../components/SideSheet";

export default R14.connect(
  class ManualEntrySideSheet extends React.Component {
    static propTypes = {
      /** Date / Time Timer is complete. */
      // completeAt: PropTypes.instanceOf(Date).isRequired,
      // /** Value of the character entry fields. */
      // value: PropTypes.any,
    };
    constructor(props) {
      super(props);
      this.handleKeyOverlayModeValueChange =
        this.handleKeyOverlayModeValueChange.bind(this);
      this.userMetricsSubscription = null;
      this.userMetricsUpdateInterval = null;
      // this.state = {
      //   userMetrics: this.props.app.dm.userSession.metrics || [],
      // };
      // console.log("UPDATE SIDESHEET TO BE MORE REACTY");
    }
    componentDidMount() {
      this.props.app.dm.userSession.subscribe();
      // console.log('HERE HERE HERE HERE');
      // this.userMetricsUpdateInterval = setInterval(async () => {
      //   // this.setState({
      //   //   userMetrics: await this.props.app.dm.userSession.fetchUserMetics(),
      //   // });
      // }, 5000);
      // this.userMetricsSubscription = this.props.app.dm.userSession.onUpdate(
      //   (manualEntryUser) => {
      //     this.setState({ userMetrics: manualEntryUser.metrics || [] });
      //   }
      // );
    }
    componentWillUnmount() {
      // this.userMetricsUpdateInterval &&
      //   clearInterval(this.userMetricsUpdateInterval);
      // this.userMetricsSubscription.unsubscribe &&
      //   this.userMetricsSubscription.unsubscribe();
    }
    handleKeyOverlayModeValueChange(value) {
      this.props.app.dm.manualEntry.setKeyOverlayMode(value);
    }
    renderSideSheetSection(key, title, ContentComponent) {
      return (
        <SideSheet.Section key={key} title={title}>
          {ContentComponent}
        </SideSheet.Section>
      );
    }
    renderSideSheetSectionItem(key, title, ContentComponent) {
      return (
        <View key={key} style={styles.sideSheetSectionItem}>
          <View style={styles.sideSheetSectionItemTitle}>
            <Text style={styles.sideSheetSectionItemTitleText}>{title}</Text>
          </View>
          <View style={styles.sideSheetSectionItemContent}>
            {ContentComponent}
          </View>
        </View>
      );
    }
    render() {
      let userMetrics = this.props.app.dm.userSession.metrics;
      let sections = [];

      sections.push(
        this.renderSideSheetSection(
          "settings",
          "Settings",
          // <Form
          //   name='copyBlockForm'
          //   onSubmit={this.handleSubmit}
          //   validateBeforeSubmit
          //   style={styles.settingsForm}
          //   initialValues={{
          //   keyOverlayMode: this.props.app.dm.manualEntry.keyOverlayMode
          //   }}
          // >
          <View style={styles.settingsField}>
            <Text style={styles.settingsFieldLabelText}>
              Show Shortcut Keys
            </Text>
            <Switch
              key='keyOverlayMode'
              name='keyOverlayMode'
              label='Key Overlay'
              value={this.props.app.dm.manualEntry.keyOverlayMode}
              onValueChange={this.handleKeyOverlayModeValueChange}
            />
          </View>
        )
      );

      sections.push(<SideSheet.Divider key='sectionDivider1' />);

      let metrics = userMetrics
        .filter((metric) => this.props.mode === metric.mode)
        .map((metric) => {
          return this.renderSideSheetSectionItem(
            `${metric.mode}-${metric.type}`,
            this.props.app.dm.manualEntry.getMetricLabel(
              metric.mode,
              metric.type
            ),
            [
              <Text
                key={`${metric.mode}-${metric.type}-value`}
                style={styles.metricValueText}
              >
                {metric.value.toFixed(1)}
              </Text>,
              <Text
                key={`${metric.mode}-${metric.type}-aggregateType`}
                style={styles.metricValueAggregateTypeText}
              >
                {this.props.app.dm.manualEntry.getMetricAggregateTypeLabel(
                  metric.aggregateType,
                  {
                    rate: metric.rate,
                  }
                )}
              </Text>,
            ]
          );
        });

      if (metrics.length) {
        sections.push(
          this.renderSideSheetSection(
            "stats",
            "Stats",
            metrics,
            this.props.mode
          )
        );
      }

      sections.push(<SideSheet.Divider key='sectionDivider2' />);

      let keyMappings = [];
      let keyMapperInfo = this.props.keyMapper.getInfo();
      for (let actionName in keyMapperInfo.actions) {
        if (actionName.startsWith("gotoChar")) continue;
        let action = keyMapperInfo.actions[actionName];
        keyMappings.push(
          this.renderSideSheetSectionItem(
            action.name,
            action.label,
            <ChipGroup>
              {action.keys.map((key) => (
                <Chip
                  size='small'
                  key={key.label}
                  label={key.label}
                  labelTextStyle={styles.keyLabelText}
                  style={styles.keyMapChip}
                />
              ))}
            </ChipGroup>
          )
        );
      }

      if (keyMappings.length) {
        sections.push(
          this.renderSideSheetSection(
            "keyMappings",
            "Shortcut Keys",
            keyMappings,
            this.props.mode
          )
        );
      }

      if (!sections.length) return null;
      return sections.length ? <SideSheet>{sections}</SideSheet> : null;
    }
  }
);

const styles = StyleSheet.create({
  sideSheetSectionTitleText: {
    fontSize: 16,
    fontWeight: 500,
  },
  metric: {},
  sideSheetSectionItem: {
    ...StyleSheet.padding(0, 0, 16, 0),
  },
  sideSheetSectionItemTitle: {
    paddingBottom: 4,
  },
  sideSheetSectionItemTitleText: {
    fontSize: 14,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.9),
  },
  metricValueText: {
    fontSize: 18,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  metricValueAggregateTypeText: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onSurface).rgba(0.7),
  },
  settingsForm: {
    // ...StyleSheet.padding(0),
    // marginBottom: -8,
  },
  settingsField: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 16,
  },
  settingsFieldLabelText: {
    fontWeight: 500,
  },
  keyLabelText: {
    fontSize: 12,
    // color: StyleSheet.color(Colors.onSurface).rgba(0.9),
    // backgroundColor: StyleSheet.color(Colors.background).rgba(0.7),
    // padding: 2,
    // borderRadius: 2,
    // marginRight: 4,
  },
  keyMapChip: {
    height: 24,
    ...StyleSheet.margin(0, 4, 4, 0),
    ...StyleSheet.padding(2),
  },
});
