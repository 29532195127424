import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import Touchable from "./Touchable";
import View from "./View";
import DraggableView from "./DraggableView";
import DraggableContainer from "./DraggableContainer";

export default class AreaSelectionView extends React.Component {
  static propTypes = {
    /** Called when area is selected. Passed object with top, left, height, width */
    onSelect: PropTypes.func.isRequired,
    /** Called when selection canceled. Passed object with top, left, height, width */
    onCancel: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.handlePressIn = this.handlePressIn.bind(this);
    this.handlePressMove = this.handlePressMove.bind(this);
    this.handlePressOut = this.handlePressOut.bind(this);
    this.handlePressCancel = this.handlePressCancel.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.state = {
      select: false,
      selection: null,
    };
  }
  handlePressIn(event) {
    if (!event || !event.nativeEvent) return;
    let { locationX, locationY } = event.nativeEvent;
    if (this.state.selection) {
      // Check if inside selection
      if (
        locationX >= this.state.selection.x &&
        locationX <= this.state.selection.x + this.state.selection.width &&
        locationY >= this.state.selection.y &&
        locationY <= this.state.selection.y + this.state.selection.height
      ) {
        return;
      }
    }
    this.setState({
      select: true,
      selection: {
        startX: locationX,
        startY: locationY,
        x: locationX,
        y: locationY,
        width: 0,
        height: 0,
      },
    });
  }
  handlePressMove(event) {
    let selection = this.calculateSelection(event);
    if (!selection) return;
    this.setState({ selection });
  }
  calculateSelection(event) {
    if (!this.state.select || !event || !event.nativeEvent) return null;
    let { locationX, locationY } = event.nativeEvent;
    let { startX, startY } = this.state.selection;
    let diffX = locationX - startX;
    let diffY = locationY - startY;
    let selection = this.state.selection;
    selection.height = Math.abs(diffY);
    selection.width = Math.abs(diffX);
    selection.x = diffX > 0 ? startX : locationX;
    selection.y = diffY > 0 ? startY : locationY;
    return selection;
  }
  handlePressOut(event) {
    let selection = this.calculateSelection(event);
    let nState = { select: false };
    let minHeight = this.props.minHeight || 2;
    let minWidth = this.props.minWidth || 2;
    if (
      (minWidth && selection.width < minWidth) ||
      (minHeight && selection.height < minHeight)
    ) {
      nState.selection = null;
      selection = null;
    }
    if (this.props.onSelect) {
      this.props.onSelect(selection);
    }
    this.setState(nState);
  }
  handlePressCancel(event) {
    if (this.props.onCancel) {
      let selection = this.calculateSelection(event);
      this.props.onCancel(selection);
    }
    this.setState({
      select: false,
      selection: null,
    });
  }
  handleDragEnd(event) {
    let selection = this.state.selection;
    selection.x += event.nativeEvent.translationX;
    selection.y += event.nativeEvent.translationY;
    selection.height = event.nativeEvent.height;
    selection.width = event.nativeEvent.width;
    console.log("HANDLE DRAG END", event);
    this.setState({ selection });
  }
  createDynamicStyles() {
    let dynamicStyles = {};
    if (this.state.select) {
      dynamicStyles.selection = {
        // top: this.state.selection.y,
        // left: this.state.selection.x,
        // height: this.state.selection.height,
        // width: this.state.selection.width,
      };
      if (this.props.backgroundColor)
        dynamicStyles.backgroundColor = this.props.backgroundColor;
    }
    return StyleSheet.create(dynamicStyles);
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <DraggableContainer
        name='areaSelection'
        style={[styles.areaSelectionView, this.props.style]}
        onPressIn={this.handlePressIn}
        onPressOut={this.state.select ? this.handlePressOut : null}
        onPressMove={this.state.select ? this.handlePressMove : null}
        onPressCancel={this.state.select ? this.handlePressCancel : null}
      >
        {this.state.selection && (
          <DraggableView
            name='areaSelection'
            style={[
              styles.selection,
              // this.props.style,
              dynamicStyles.selection,
            ]}
            offsetTop={this.state.selection.y}
            offsetLeft={this.state.selection.x}
            height={this.state.selection.height}
            width={this.state.selection.width}
            disabled={this.state.select}
            onDragEnd={this.handleDragEnd}
            resizable={!this.state.select}
          />
        )}
      </DraggableContainer>
    );
  }
}

const styles = StyleSheet.create({
  areaSelectionView: {
    position: "relative",
    // ...StyleSheet.absoluteFill,
    cursor: "crosshair",
    //  backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.1),
  },
  selection: {
    position: "absolute",
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.3),
  },
});
