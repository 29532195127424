// import DevDomain from '../domains/DevDomain';
import UserSessionDomain from '../domains/UserSessionDomain';
// import PipelineBlockDomain from '../domains/PipelineBlockDomain';
import ManualEntryDomain from '../domains/ManualEntryDomain';
import VirtualWorkspaceDomain from '../domains/VirtualWorkspaceDomain';
import UserDomain from '../domains/UserDomain';
import R14LicenseDomain from '../lib/domains/R14LicenseDomain';

import CharactersEntryFieldUiDomain from '../domains/CharactersEntryFieldUiDomain';
import DocumentSetUiDomain from '../domains/DocumentSetUiDomain';
export default {
  domain: {
    // dev: DevDomain,
    userSession: UserSessionDomain,
    // pipelineBlock: PipelineBlockDomain,
    manualEntry: ManualEntryDomain,
    virtualWorkspace: VirtualWorkspaceDomain,
    user: UserDomain,
    r14License: R14LicenseDomain,
  },
  ui: {
    charactersEntryField: CharactersEntryFieldUiDomain,
    documentSet: DocumentSetUiDomain
  }
}