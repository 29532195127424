import R14 from "../R14";

export default class ProgressIndicatorUIDomain extends R14.Domain {
  constructor() {
    super();
    this.state = {
      instances: {}
    };
  }
  show(name = "default", options = {}) {
    if (typeof name === "object") {
      options = name;
      name = options.name || "default";
    }
    if (!this.state.instances[name]) {
      let instances = this.state.instances;
      instances[name] = options;
      this.setState({
        instances: instances
      });
    }
  }
  hide(name = "default", options = {}) {
    if (typeof name === "object") {
      options = name;
      name = options.name || "default";
    }
    if (this.state.instances[name]) {
      // Check for timeout
      let timeout = options.timeout || 0;
      let _hide = name => {
        let instances = this.state.instances;
        delete instances[name];
        this.setState({
          instances: instances
        });
      };
      timeout ? setTimeout(() => _hide(name), timeout) : _hide(name);
    }
  }
  isVisible(name = "default") {
    return this.state.instances[name] ? true : false;
  }
  shouldOverlay(name = "default") {
    if(! this.isVisible(name)) return false;
    return this.state.instances[name].overlay === false ? false : true;
  }
}
