import React from "react";
import PropTypes from "prop-types";
import R14, {
  StyleSheet,
  Image,
  View,
  Surface,
  ZoomableView,
  Colors,
  IconButton,
  ScreenHeader,
  ScrollView,
} from "../core";
export default class Document extends React.Component {
  static propTypes = {
    /** Position of the toolbar */
    toolbarPosition: PropTypes.oneOf([
      "bottomRight",
      "bottomCenter",
      "topRight",
    ]),
  };
  static defaultProps = {
    toolbarPosition: "bottomRight",
  };
  constructor(props) {
    super(props);
    this.handleAnnotationPress = this.handleAnnotationPress.bind(this);
    // this.handleLayout = this.handleLayout.bind(this);
    // this.handleZoomIn = this.handleZoomIn.bind(this);
    // this.handleZoomOut = this.handleZoomOut.bind(this);
    this.scrollRef = React.createRef();
  }
  // get page() {
  //   return this.props.page;
  // }
  get showHeader() {
    return this.props.headerTitle ||
      this.props.headerControlsRight ||
      this.props.headerControlsLeft
      ? true
      : false;
  }
  componentDidMount() {
    if (this.props.initialScroll && this.scrollRef && this.scrollRef.current) {
      this.scrollTo({
        x: this.props.initialScroll.x,
        y: this.props.initialScroll.y,
        animated: false,
      });
    }
  }
  scrollTo(params) {
    if (this.scrollRef && this.scrollRef.current) {
      this.scrollRef.current.scrollTo(params);
    }
  }
  handleAnnotationPress(annotation) {
    console.log("handleAnnotationPress", annotation);
  }
  // handleZoomIn() {
  //   this.props.page.zoomIn();
  // }
  // handleZoomOut() {
  //   this.props.page.zoomOut();
  // }

  renderToolbar() {
    if (!this.props.onZoomInPress && !this.props.onZoomOutPress) return null;

    let toolbarStyles = [styles.toolbar];
    switch (this.props.toolbarPosition) {
      case "bottomRight":
        toolbarStyles.push(styles.toolbarBottomRight);
        break;
      case "bottomCenter":
        toolbarStyles.push(styles.toolbarBottomCenter);
        break;
      case "topRight":
        toolbarStyles.push(styles.toolbarTopRight);
        this.showHeader && toolbarStyles.push(styles.toolbarTopHeader);
        break;
      default:
        throw new Error(
          `Unknown toolbar position: ${this.props.toolbarPosition}`
        );
    }
    this.props.toolbarStyle && toolbarStyles.push(this.props.toolbarStyle);

    return (
      <Surface style={toolbarStyles} elevation={8}>
        <IconButton
          icon='add'
          key='zoomIn'
          size='small'
          style={[
            styles.toolbarButton,
            this.props.zoomLevel > 1 && styles.zoomButtonActive,
          ]}
          onPress={this.props.onZoomInPress}
        />
        {this.props.onZoomResetPress && (
          <IconButton
            icon='fitToPage'
            key='zoomReset'
            size='small'
            style={[styles.toolbarButton]}
            onPress={this.props.onZoomResetPress}
            // disabled={this.props.zoomLevel === 1}
          />
        )}
        <IconButton
          icon='minus'
          key='zoomOut'
          size='small'
          style={[
            styles.toolbarButton,
            this.props.zoomLevel < 1 && styles.zoomButtonActive,
          ]}
          onPress={this.props.onZoomOutPress}
        />
        {this.props.rotateUpdated && (
          <IconButton
            icon='contentSave'
            key='contentSave'
            size='small'
            color={Colors.secondary}
            style={[styles.toolbarButton]}
            onPress={this.props.onRotationSavePress}
            tooltip='Save Changes'
          />
        )}
        <IconButton
          icon='rotateLeft'
          key='rotateLeft'
          size='small'
          style={[
            styles.toolbarButton,
            this.props.rotate < 0 && styles.zoomButtonActive,
          ]}
          onPress={this.props.onRotateLeftPress}
        />
        <IconButton
          icon='rotateRight'
          key='rotateRight'
          size='small'
          style={[
            styles.toolbarButton,
            this.props.rotate > 0 && styles.zoomButtonActive,
          ]}
          onPress={this.props.onRotateRightPress}
        />
      </Surface>
    );
  }
  renderHeaderControlsRight() {
    if (
      !this.props.headerControlsRight &&
      !this.props.onZoomInPress &&
      !this.props.onZoomOutPress
    )
      return null;
    let headerControls = this.props.headerControlsRight || null;
    // return [
    //   <IconButton
    //     icon='magnifyPlus'
    //     key='zoomIn'
    //     size='small'
    //     style={[
    //       styles.toolbarButton,
    //       this.props.zoomLevel > 1 && styles.zoomButtonActive,
    //     ]}
    //     onPress={this.props.onZoomInPress}
    //   />,
    //   <IconButton
    //     icon='magnifyMinus'
    //     key='zoomOut'
    //     size='small'
    //     style={[
    //       styles.toolbarButton,
    //       this.props.zoomLevel < 1 && styles.zoomButtonActive,
    //     ]}
    //     onPress={this.props.onZoomOutPress}
    //   />,
    // ];
    return headerControls;
  }
  render() {
    // if (!this.page.state.layout) return false;
    let dynamicStyles = this.createDynamicStyles();
    let scale = this.props.scale;
    return (
      <View style={styles.document} onLayout={this.props.onLayout || null}>
        {/* <ScrollView
          onLayout={this.props.onScrollViewLayout || null}
          onScroll={this.props.onScroll || null}
          ref={this.scrollRef}
          overflow={true}
        >
          <View
            onLayout={(event) => console.log("LAYOUT CHANGE?", event)}
            style={[
              styles.document,
              this.showHeader ? styles.headerDocument : null,
            ]}
          > */}
        {this.showHeader && (
          <ScreenHeader
            style={[styles.header, this.props.headerStyle]}
            contentStyle={styles.headerContent}
            headerTitleTextStyle={styles.headerTitleText}
            title={this.props.headerTitle}
            controlsRight={this.renderHeaderControlsRight()}
          />
        )}
        <View style={styles.documentZoomableWrapper}>
          <ZoomableView
            style={styles.zoomableView}
            level={this.props.zoomLevel}
            //level={.3}
            height={this.props.imageHeight * this.props.scale}
            width={this.props.imageWidth * this.props.scale}
            padding={32}
            backgroundColor={StyleSheet.color(Colors.onBackground).rgba(0.5)}
            duration={150}
            scrollable
            onScrollViewLayout={this.props.onScrollViewLayout || null}
            onScroll={this.props.onScroll || null}
            // scrollViewStyle={dynamicStyles.scrollView}
            // scrollViewContentStyle={[
            //   styles.scrollViewContent,
            //   // dynamicStyles.scrollViewContent,
            //   // this.showHeader ? styles.headerDocument : null,
            // ]}
            rotate={this.props.rotate}
            scrollRef={this.scrollRef}
          >
            <Surface
              elevation={12}
              style={[styles.imageWrapper, dynamicStyles.imageWrapper]}
            >
              <Image
                resizeMode='cover'
                style={[styles.image, dynamicStyles.image]}
                height={this.props.imageHeight * scale}
                width={this.props.imageWidth * scale}
                auth={this.props.imageAuth ? true : false}
                source={this.props.imageSource}
              />
              {this.props.annotations}
            </Surface>
          </ZoomableView>
        </View>
        {/* </View>
        </ScrollView> */}

        {this.renderToolbar()}
      </View>
    );
  }
  createDynamicStyles() {
    let scale = this.props.scale;
    let height = this.props.height;
    let width = this.props.width;
    let imageHeight = this.props.imageHeight;
    let imageWidth = this.props.imageWidth;
    let padding = this.props.padding || 0;
    return StyleSheet.create({
      scrollViewContent: {
        // height:
        //   this.props.imageHeight * this.props.scale * this.props.zoomLevel,
        // // (this.showHeader ? 88 : 0),
        // width: this.props.imageWidth * this.props.scale * this.props.zoomLevel,
        // ...StyleSheet.padding(padding),
        // ...StyleSheet.padding(
        //   //padding + this.showHeader ? 88 : 0,
        //   padding,
        //   padding,
        //   padding,
        //   padding
        // ),
      },
      // scrollView: {
      //   top: this.showHeader ? 56 : 0,
      // },
      image: {
        // transform: [
        //   {
        //     scale: scale,
        //     translateX: (imageWidth * scale - imageWidth) / (scale * 2),
        //     translateY: (imageHeight * scale - imageHeight) / (scale * 2),
        //   },
        // ],
      },
      imageWrapper: {
        height: imageHeight * scale,
        width: imageWidth * scale,
        ...StyleSheet.padding(0),
        ...StyleSheet.margin(0),
        // marginTop:
        //   imageHeight * scale > height
        //     ? 0
        //     : (height - imageHeight * scale) / 2,
        // marginLeft:
        //   imageWidth * scale > width ? 0 : (width - imageWidth * scale) / 2,
        // transform: [
        //   {
        //     // scale: scale,
        //     translateX:
        //       imageWidth * scale > width
        //         ? 0
        //         : (width - imageWidth * scale) / 2,
        //     translateY:
        //       imageHeight * scale > height
        //         ? 0
        //         : (height - imageHeight * scale) / 2,
        //   },
        // ],
      },
    });
  }
}

const styles = StyleSheet.create({
  scrollViewContent: {
    // position: "relative",
    // width: "100%",
    // flex: 1,
    // // flexWrap: "nowrap",
    // flexDirection: "row",
    // ...StyleSheet.padding(32),
    // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.5),
  },
  document: {
    flex: 1,
    flexDirection: "column",
  },
  documentZoomableWrapper: {
    flex: 1,
    position: "relative",
  },
  header: {
    flex: 0,
    // position: "absolute",
    // top: 0,
    // right: 0,
    // left: 0,
    height: 56,
    width: "100%",
    ...StyleSheet.margin(0),
    // backgroundColor: StyleSheet.color(Colors.background).rgba(0.5),
  },
  headerContent: {
    ...StyleSheet.padding(10, 16, 10, 16),
  },
  headerTitleText: {
    fontSize: 16,
  },
  headerScrollView: {
    // top: 56,
  },
  image: {
    flex: 1,
    backgroundPosition: "center",
  },
  imageWrapper: {
    flex: 0,
    //position: "absolute",
    //overflow: "hidden",
  },
  toolbarButton: {
    // borderRadius: 18,
    ...StyleSheet.margin(0),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  zoomButtonActive: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
  zoomableView: {
    // top: 88,
    // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.5),
  },
  toolbar: {
    position: "absolute",
    // backgroundColor: StyleSheet.color(Colors.surface).rgba(0.8),
    backgroundColor: Colors.surface,
    flex: 1,
    flexDirection: "row",
    borderRadius: 18,
    overflow: "hidden",
    height: 36,
    alignSelf: "right",
    ...StyleSheet.margin(0),
    ...StyleSheet.padding(0),
    // zIndex: 3
  },
  toolbarBottomRight: {
    bottom: 8,
    right: 8,
  },
  toolbarTopRight: {
    top: 8,
    right: 8,
  },
  toolbarBottomCenter: {
    bottom: 8,
    alignSelf: "center",
  },
  toolbarTopHeader: {
    top: 64,
  },
});
