import React from "react";
import R14, {
  View,
  Text,
  IconButton,
  Icon,
  StyleSheet,
  SwitchField,
  Form,
  Colors,
  PopUpMenu,
  PopUpMenuItem,
  Theme,
} from "../core";

export default R14.connect(
  class HeaderControlsRight extends React.Component {
    constructor(props) {
      super(props);
      this.handleLogoutPress = this.handleLogoutPress.bind(this);
      this.handleHistoryPress = this.handleHistoryPress.bind(this);
      this.handleQueuePress = this.handleQueuePress.bind(this);
      this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
      this.handleDeploymentsPress = this.handleDeploymentsPress.bind(this);
      this.handleVirtualWorkspacesPress =
        this.handleVirtualWorkspacesPress.bind(this);
      this.handleToggleThemePress = this.handleToggleThemePress.bind(this);
    }
    async handleLogoutPress() {
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.userSession.logout();

      if (!this.props.app.dm.userSession.redirectToR14Login())
        this.props.app.nav.to("login");

      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    handleUpdatePassword() {
      this.props.app.dm.userSession.redirectToR14Login("/account/password");
    }
    handleHistoryPress() {
      this.props.app.nav.to("history");
    }
    handleQueuePress() {
      this.props.app.nav.to("manualEntryLoad");
    }
    handleVirtualWorkspacesPress() {
      this.props.app.nav.to("services");
    }
    handleToggleThemePress() {
      let themeKey = "default";
      if (Theme.key === "default") themeKey = "light";
      this.props.app.dm.theme.setTheme(themeKey);
    }
    handleDeploymentsPress() {
      this.props.app.dm.userSession.redirectToR14Login("/deployments");
    }
    renderPopUpMenu() {
      let role = this.props.app.dm.userSession.role;
      let items = [];
      items.push(
        <View key='header' style={styles.header}>
          <View style={styles.headerName}>
            <Text>
              {this.props.app.dm.userSession.name ||
                this.props.app.dm.userSession.username ||
                this.props.app.dm.userSession.email}
            </Text>
          </View>
          <View style={styles.headerClient}>
            <Text style={styles.headerClientText}>
              {this.props.app.dm.userSession.clientName}
            </Text>
          </View>
        </View>
      );
      // items.push(
      //   <PopUpMenuItem
      //     icon='accountSettings'
      //     iconSize='small'
      //     label='Account Settings'
      //     onPress={() => this.props.app.nav.to("accountSettings")}
      //     key='accountSettings'
      //   />
      // );
      // if (role === this.props.app.dm.user.ROLE_SUPER_ADMIN)
      //   items.push(
      //     <PopUpMenuItem
      //       icon='accountGroup'
      //       iconSize='small'
      //       label='Manage Clients'
      //       onPress={() => this.props.app.nav.to("clients")}
      //       key='manageClients'
      //     />
      // //   );
      // items.push(
      //   <PopUpMenuItem
      //     icon={
      //       Theme.key === "default" ? "checkboxMarked" : "checkboxBlank"
      //     }
      //     iconSize='small'
      //     label="Dark Mode"
      //     onPress={this.handleToggleThemePress}
      //     key='theme'
      //   />
      // );

      items.push(
        <PopUpMenuItem
          icon='account'
          iconSize='small'
          label='Update Password'
          onPress={this.handleUpdatePassword}
          key='accountSettings'
        />
      );
      this.props.app.dm.userSession.manualEntryConfigExists &&
        items.push(
          <PopUpMenuItem
            icon='keyboardOutline'
            iconSize='small'
            label='Your Queue'
            onPress={this.handleQueuePress}
            key='queue'
          />
        );

      this.props.app.dm.userSession.virtualWorkspaceConfigExists &&
        items.push(
          <PopUpMenuItem
            icon='developerBoard'
            iconSize='small'
            label='Virtual Workspace(s)'
            onPress={this.handleVirtualWorkspacesPress}
            key='virtualWorkspaces'
          />
        );
      items.push(
        <PopUpMenuItem
          icon='clock'
          iconSize='small'
          label='History'
          onPress={this.handleHistoryPress}
          key='history'
        />
      );
      items.push(
        <PopUpMenuItem
          icon='accountGroup'
          iconSize='small'
          label='Deployments'
          onPress={this.handleDeploymentsPress}
          key='deployments'
        />
      );
      items.push(
        <PopUpMenuItem
          icon='lock'
          iconSize='small'
          label='Logout'
          onPress={this.handleLogoutPress}
          key='logout'
        />
      );
      return (
        <PopUpMenu
          controlIcon='accountCircle'
          controlIconColor={
            Theme.componentColors("header", {
              default: Colors.onPrimary,
            }).onPrimary
          }
          key='headerControlsLeftMenu'
          direction='downLeft'
          style={styles.accountMenu}
          onSelect={(value, label) => {}}
        >
          {items}
        </PopUpMenu>
      );
    }
    render() {
      let isLoggedIn = this.props.app.dm.userSession.isLoggedIn;
      return (
        <View style={styles.headerControlsRight}>
          {!isLoggedIn && (
            <IconButton
              to='login'
              icon='lock'
              color={Colors.onPrimary}
              // color={
              //   Theme.componentColors("header", {
              //     default: Colors.onPrimary
              //   }).onPrimary
              // }
            />
          )}
          {isLoggedIn && this.renderPopUpMenu()}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 6,
    paddingLeft: 6,
  },
  accountMenu: {
    width: 256,
    maxHeight: 320,
  },
  header: {
    flex: 1,
    flexDirection: "column",
    borderBottomWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
    borderStyle: "solid",
    ...StyleSheet.padding(0, 8, 8, 12),
  },
  headerClientText: {
    fontSize: 14,
    fontWeight: "300",
  },
});
