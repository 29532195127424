import React from "react";
import StyleSheet from "../StyleSheet";
import ResizeObserver from "resize-observer-polyfill";

export default class TextBase extends React.Component {
  constructor(props) {
    super(props);
    this.htmlRef = this.props.htmlRef || React.createRef();
  }
  render() {
    let props = {};
    let htmlTag = this.props.htmlTag || "span";
    let styles = this.styles();
    let noWrap = this.props.noWrap ? true : false;
    let ellipsisMode = false;
    let numberOfLines = this.props.numberOfLines ? true : false;

    if (this.props.tooltip) props.title = this.props.tooltip;

    if (numberOfLines) {
      ellipsisMode = this.props.ellipsisMode ? true : false;
      if (this.props.numberOfLines === 1) {
        numberOfLines = false;
        noWrap = true;
        ellipsisMode = true;
      }
      if (this.props.numberOfLines > 1) {
        console.error("Text property numberOfLines not supported.");
        numberOfLines = false;
      }
    }

    let hasStyles =
      this.props.pointerEvents ||
      numberOfLines ||
      ellipsisMode ||
      noWrap ||
      this.props.style;
    if (hasStyles)
      props.className = StyleSheet.className([
        this.props.pointerEvents && styles.pointerEvents,
        numberOfLines && styles.numberOfLines,
        ellipsisMode && styles.ellipsisMode,
        noWrap && styles.noWrap,
        this.props.style,
      ]);

    let TextComponent = React.createElement(
      htmlTag,
      {
        ref: this.htmlRef,
        ...props,
      },
      this.props.children
    );
    if (this.props.onLayout) {
      this.resizeObserver = new ResizeObserver((entries) => {
        this.triggerOnLayout();
      });
    }
    return TextComponent;
  }
  triggerOnLayout() {
    if (!this.props.onLayout) return false;
    let ret = {
      nativeEvent: {
        layout: {
          x: this.htmlRef.current.offsetLeft,
          y: this.htmlRef.current.offsetTop,
          width: this.htmlRef.current.offsetWidth,
          height: this.htmlRef.current.offsetHeight,
        },
      },
    };
    this.props.onLayout(ret);
  }
  componentDidMount() {
    /** @todo should the view check for layout changes before calling this? Use   observer? */
    if (this.props.onLayout) {
      //setTimeout(()=>{
      this.triggerOnLayout();
      //},5);
      this.resizeObserver.observe(this.htmlRef.current);
    }
  }
  styles() {
    return StyleSheet.create({
      noWrap: {
        whiteSpace: "nowrap",
      },
      pointerEvents: {
        pointerEvents: this.props.pointerEvents,
      },
      numberOfLines: {
        lineClamp: this.props.numberOfLines,
        "-webkit-line-clamp": this.props.numberOfLines,
      },
      ellipsisMode: {
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    });
  }
}
