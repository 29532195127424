import React from "react";
import R14, {
  Redirect,
  Button,
  SubmitButton,
  StyleSheet,
  Text,
  View,
  SectionHeader,
  Colors,
  CenteredForm,
  Touchable,
} from "../core";
import NdaDialog from "../components/NdaDialog";
export default R14.connect(
  class LoginScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleNdaDialogClose = this.handleNdaDialogClose.bind(this);
      this.showNdaDialog = this.showNdaDialog.bind(this);
      this._manualEntryUserSession = this.props.manualEntryUserSession;
      this.state = {
        uuid: this._manualEntryUserSession.uuid,
        ndaDialogVisible: false,
      };
    }
    get manualEntryUserSession() {
      return this._manualEntryUserSession;
    }
    handleNdaDialogClose() {
      this.setState({ ndaDialogVisible: false });
    }
    showNdaDialog() {
      this.setState({ ndaDialogVisible: true });
    }
    async handleSubmit(form) {
      let redirect = null;
      try {
        this.props.app.ui.progressIndicator.show();
        let confirmed = await this.props.app.dm.manualEntry.confirmUserSession(
          this.state.uuid
        );
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });

        if (confirmed) {
          this.props.app.ui.snackBar.show({
            message: "Session Confirmed!",
            variant: "success",
          });
          redirect = <Redirect to='manualEntryLoad' />;
        } else {
          this.props.app.ui.snackBar.show({
            message: "Could not confirm session, please try again.",
            variant: "error",
          });
        }
      } catch (err) {
        this.props.app.ui.snackBar.show({
          message: "Could not confirm session.",
          variant: "error",
        });
      }
      return redirect;
    }
    renderRates() {
      let pricingInfo =
        this.props.app.dm.manualEntry.getUserSessionPricingDisplayInfo(
          this.manualEntryUserSession
        );

      return (
        <View>
          {pricingInfo.map((mode) => (
            <View key={mode.key} style={styles.modePricing}>
              <SectionHeader level={2} style={styles.sectionHeader}>
                {mode.label}
              </SectionHeader>
              {mode.rates.map((rate) => (
                <View key={rate.metric}>
                  <Text>{rate.label}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      );
    }
    render() {
      return (
        <CenteredForm
          name='confirmUserSessionForm'
          title='Start New Session'
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          style={styles.centeredForm}
          controlsBottomRight={[
            <Button
              title='Logout'
              key='logout'
              variant='text'
              onPress={() => this.props.app.nav.to("login")}
            />,
            <SubmitButton title='Accept' key='accept' />,
          ]}
        >
          <NdaDialog
            visible={this.state.ndaDialogVisible}
            onClosePress={this.handleNdaDialogClose}
          />
          <View style={styles.wrapper}>
            <View style={styles.info}>
              <Text>
                You are starting a new session. Please review the rates below
                and confirm.
              </Text>
            </View>
            {this.renderRates()}
            {this.props.app.dm.manualEntry.ndaAcceptRequired && (
              <Touchable
                style={styles.ndaTouchable}
                onPress={this.showNdaDialog}
              >
                <Text>
                  By pressing the "Accept" button below, you agree to the
                  following{" "}
                  <Text style={styles.ndaTouchableText}>
                    Terms and Conditions
                  </Text>
                  .
                </Text>
              </Touchable>
            )}
          </View>
        </CenteredForm>
      );
    }
  }
);

const styles = StyleSheet.create({
  centeredForm: {
    maxWidth: 448,
  },
  wrapper: {
    paddingBottom: 8,
  },
  sectionHeader: {
    fontSize: 14,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
    paddingBottom: 8,
  },
  modePricing: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    ...StyleSheet.padding(8),
    ...StyleSheet.margin(0, -8, 8, -8),
  },
  info: {
    paddingBottom: 12,
  },
  infoText: {},
  ndaTouchable: {
    ...StyleSheet.padding(8, 0, 8, 0),
  },
  ndaTouchableText: {
    textDecorationLine: "underline",
    color: Colors.primary,
  },
});
