import React from "react";
import StyleSheet from "./StyleSheet";
import PropTypes from "prop-types";
import { Colors } from "./Theme";
import View from "./View";
import Text from "./Text";
import Touchable from "./Touchable";
import Icon from "./Icon";

export default class Chip extends React.PureComponent {
  static propTypes = {
    /** The external url to open */
    label: PropTypes.string.isRequired,
    /** The function to be called when the chip is pressed. */
    onPress: PropTypes.func,
    /** The chip variant to use, defaults to filled */
    variant: PropTypes.oneOf(["outlined", "filled"]),
    /** The name of the icon or component to be displayed to the left of the chip label  */
    iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** The name of the icon or component to be displayed to the right of the chip label  */
    iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the chip */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the chip label */
    labelStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    /** The LabelComponent to be used inside the chip. */
    LabelComponent: PropTypes.node,
    /** The background color of the chip. */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The color of the chip label. */
    labelColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the chip label text */
    labelTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    /** Whether the chip label text should wrap */
    noWrap: PropTypes.bool,
    /** Whether the chip is selected */
    selected: PropTypes.bool,
  };
  static defaultProps = {
    noWrap: true,
  };
  renderIcon(type) {
    let icon = null;
    let iconProp = null;
    let iconStyle = null;
    switch (type) {
      case "left":
        iconProp = this.props.iconLeft;
        iconStyle = styles.iconLeft;
        break;
      case "right":
        iconProp = this.props.iconRight;
        iconStyle = styles.iconRight;
        break;
    }
    if (!iconProp) return null;

    if (typeof iconProp === "string")
      icon = <Icon name={iconProp} size='small' />;
    else icon = iconProp;
    return <View style={iconStyle}>{icon}</View>;
  }
  render() {
    let ContainerComponent = this.props.onPress ? Touchable : View;
    let dynamicStyles = StyleSheet.create({
      chip: {
        backgroundColor:
          this.props.color || StyleSheet.color(Colors.onSurface).rgba(0.12),
      },
      label: {
        color: this.props.labelColor || Colors.onSurface,
      },
      selected: {
        backgroundColor:
          this.props.selectedColor || StyleSheet.color(Colors.primary).rgba(0.1),
      },
      selectedLabel: {
        color:
          this.props.selectedLabelColor || Colors.primary,
      }
    });
    let iconLeft = null;
    let label = this.props.LabelComponent || (
      <Text
        noWrap={this.props.noWrap}
        style={[
          styles.label,
          dynamicStyles.label,
          this.props.labelTextStyle,
          this.props.selected && dynamicStyles.selectedLabel,
        ]}
      >
        {this.props.label}
      </Text>
    );
    return (
      <ContainerComponent
        onPress={this.props.onPress}
        style={[
          styles.chip,
          dynamicStyles.chip,
          this.props.style,
          this.props.selected && dynamicStyles.selected,
        ]}
      >
        {this.renderIcon("left")}
        {label}
        {this.renderIcon("right")}
      </ContainerComponent>
    );
  }
}
const styles = StyleSheet.create({
  chip: {
    flex: 0,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 16,
    height: 32,
    ...StyleSheet.padding(4, 4, 4, 4),
    ...StyleSheet.margin(0, 8, 8, 0),
  },
  label: {
    color: Colors.secondary,
    fontSize: 14,
    ...StyleSheet.padding(0, 8, 0, 8),
  },
  iconLeft: {
    ...StyleSheet.padding(0, 0, 0, 4),
    marginRight: -4,
  },
  iconRight: {
    ...StyleSheet.padding(0, 4, 0, 0),
    marginLeft: -4,
  },
  selected: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.1),
  },
  selectedLabel: {
    color: Colors.primary,
  },
});
