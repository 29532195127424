import React from "react";
import PropTypes from "prop-types";
import Link from "./Link";
import ExternalLink from "./ExternalLink";
import Icon from "./Icon";
import Text from "./Text";
import View from "./View";
import Touchable from "./Touchable";
import StyleSheet from "./StyleSheet";
import Ripple from "./Ripple";
import { Colors } from "./Theme";

/**
 * A touchable icon button
 */
export default class IconButton extends React.PureComponent {
  static propTypes = {
    /** Title text displayed next to the icon. */
    title: PropTypes.string,
    /** The color of the text and icon  */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The background color of the icon button  */
    backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** Style variants for the icon button */
    size: PropTypes.oneOf(["circle"]),
    /** The size of the button */
    size: PropTypes.oneOf(["large", "small", "medium"]),
    /** If true, disable the button */
    disabled: PropTypes.bool,
    /** The route to navigate to on press. Can either be a route name, or a route object containing route and params. */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** An external link to navigate to on press. */
    externalUrl: PropTypes.string,
    /** A StyleSheet object, or array of StyleSheet objects to apply to the button */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    /** Handler to be called when the user taps the button  */
    onPress: PropTypes.func,
    /** The name of the icon to be displayed in the button  */
    icon: PropTypes.string,
  };
  static defaultProps = {
    size: "medium",
  };
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }
  handlePress(e) {
    if (!this.props.disabled && this.props.onPress) this.props.onPress(e);
  }
  render() {
    let iconButtonStyles = [styles.iconButton];
    let iconColor =
      this.props.color || StyleSheet.color(Colors.onBackground).rgba(0.8);

    iconButtonStyles.push(styles[this.props.size]);

    if (this.props.variant)
      iconButtonStyles.push(variantStyles[this.props.variant]);

    switch (this.props.variant) {
      case "circle":
        iconColor =
          this.props.color || StyleSheet.color(Colors.onSecondary).rgba(0.8);
        break;
    }

    if (this.props.color || this.props.backgroundColor) {
      let colorStyle = {};
      if (this.props.color) colorStyle.color = this.props.color;
      if (this.props.backgroundColor)
        colorStyle.backgroundColor = this.props.backgroundColor;
      let colorStyles = StyleSheet.create({
        iconButton: colorStyle,
      });
      iconButtonStyles.push(colorStyles.iconButton);
    }

    let title = null;
    if (this.props.title) {
      let titleStyles = [styles.title];
      iconButtonStyles.push(styles.iconButtonTitle);
      if (this.props.titlePosition === "bottom") {
        titleStyles.push(styles.titleBottom);
        iconButtonStyles.push(styles.iconButtonTitleBottom);
      }
      title = (
        <Text style={titleStyles} color={iconColor}>
          {this.props.title}
        </Text>
      );
    }
    if (this.props.disabled) iconButtonStyles.push(styles.disabled);
    if (this.props.style) iconButtonStyles.push(this.props.style);

    let nProps = {};
    if (this.props.disabled) nProps.disabled = true;

    let icon = (
      <Icon
        name={this.props.icon}
        disabled={this.props.disabled}
        color={iconColor}
        size={this.props.size}
        selected={this.props.selected || null}
        filled={this.props.filled || null}
        tooltip={this.props.tooltip || null}
        style={styles.icon}
      />
    );
    if (this.props.to) {
      nProps.style = iconButtonStyles;
      nProps.exact = this.props.exact || null;
      nProps.activeStyle = this.props.activeStyle || null;
      nProps.to = this.props.to;
      return (
        <Link {...nProps}>
          {icon}
          {title}
        </Link>
      );
    }
    if (this.props.externalUrl) {
      nProps.style = iconButtonStyles;
      nProps.activeStyle = this.props.activeStyle || null;
      nProps.url = this.props.externalUrl;
      return (
        <ExternalLink {...nProps}>
          {icon}
          {title}
        </ExternalLink>
      );
    } else {
      nProps.onPress = this.handlePress;
      nProps.onPressMove = this.props.onPressMove || null;
      return (
        <View style={iconButtonStyles}>
          {icon}
          {title}
          <Touchable
            style={styles.touchable}
            ref={this.props.touchableRef || null}
            elementRef={this.props.elementRef || null}
            onPress={this.handlePress}
            onPressMove={this.props.onPressMove || null}
            disabled={this.props.disabled || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            autoFocus={this.props.autoFocus}
            tabIndex={this.props.tabIndex}
            onKeyDown={this.props.onKeyDown}
          >
            <View style={styles.touchable} />
          </Touchable>
        </View>
      );
    }
  }
}
const styles = StyleSheet.create({
  // touchable: {
  //   position: 'relative'
  // },
  touchable: {
    flex: 1,
    position: "absolute",
    ...StyleSheet.absoluteFill,
  },
  iconButton: {
    // boxSizing: "initial",
    position: "relative",
    flex: 0,
    flexDirection: "row",
    // height: 56,
    flexWrap: "nowrap",
    // position: "relative",
    backgroundColor: "transparent",
    height: 48,
    width: "auto",
    padding: 6,
    // lineHeight: 24,
    // padding: 12,
    marginTop: 0,
    marginRight: 4,
    marginBottom: 0,
    marginLeft: 4,
    // border: 0,
    fontSize: 15,
    overflow: "visible",
    textAlign: "center",
    // textDecoration: "none",
    color: Colors.onBackground,
    borderRadius: 4,
    overflow: "hidden",
    // outline: "none",
    // cursor: "pointer"
  },
  disabled: {
    // color: StyleSheet.color(Colors.primary).rgba(.5)
    opacity: 0.5,
  },
  disabledText: {
    color: StyleSheet.color(Colors.primary).rgba(0.5),
  },
  small: {
    height: 36,
    padding: 8,
    lineHeight: 20,
  },
  medium: {
    height: 36,
    width: "auto",
    padding: 6,
  },
  title: {
    width: "auto",
    lineHeight: 24,
    height: "100%",
    paddingLeft: 4,
  },
  titleSmall: {
    lineHeight: 20,
    fontSize: 13,
  },
  iconButtonTitleBottom: {
    height: 40,
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  titleBottom: {
    paddingLeft: 0,
  },
});

const variantStyles = StyleSheet.create({
  circle: {
    backgroundColor: Colors.secondary,
    borderRadius: 24,
    elevation: 1,
  },
});
