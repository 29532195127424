export default {
  default: {
    colors: {
      primary: "#0097a7",
      onPrimary: "#FFFFFF",
      primaryLight: "#56c8d8",
      onPrimaryLight: "#FFFFFF",
      primaryDark: "#006978",
      onPrimaryDark: "#FFFFFF",
      secondary: "#c62828",
      onSecondary: "#FFFFFF",
      secondaryLight: "#ff5f52",
      onSecondaryLight: "#FFFFFF",
      secondaryDark: "#8e0000",
      onSecondaryDark: "#FFFFFF",
      background: "#1b1b1b",
      onBackground: "#FFFFFF",
      surface: "#212121",
      onSurface: "#FFFFFF",
      error: "#B00020",
      onError: "#FFFFFF",
      green: "#4caf50",
      yellow: "#ffeb3b",
      activeQueueItem: "#ffeb3b"
    }
  },
  light: {
    colors: {
      primary: "#039be5",
      onPrimary: "#FFFFFF",
      primaryLight: "#63ccff",
      onPrimaryLight: "#FFFFFF",
      primaryDark: "#006db3",
      onPrimaryDark: "#FFFFFF",
      secondary: "#f4511e",
      onSecondary: "#FFFFFF",
      SecondaryLight: "#ff844c",
      onSecondaryLight: "#FFFFFF",
      secondaryDark: "#b91400",
      onSecondaryDark: "#FFFFFF",
      background: "#F6F6F6",
      onBackground: "#1c1c1c",
      surface: "#FFFFFF",
      onSurface: "#1c1c1c",
      error: "#B00020",
      onError: "#FFFFFF",
      green: "#4caf50",
      yellow: "#ffeb3b"
    }
  }
};
