import React from 'react';
import R14 from '../R14';
import View from './View';
// import RootPortal from './RootPortal';
import Dimensions from './Dimensions';
import StyleSheet, { css } from './StyleSheet';
import { Colors } from './Theme';
import AnimatedView from './AnimatedView';
import Modal from './Modal';

const ProgressIndicator = R14.connect(
  class ProgressIndicator extends React.Component {
    constructor(props){
      super(props);
    }
    render() {
      // Todo: Bind props.visible to domain.isVisible
      let windowWidth = Dimensions.get("window").width;
      
      return (
        // \\<RootPortal>
        <Modal
          visible={this.props.app.ui.progressIndicator.isVisible(this.props.name)}
          transparent
          animationType="fade"
        >
          <View
             style={styles.progressIndicator}
          >
            {this.props.app.ui.progressIndicator.shouldOverlay(this.props.name) &&
              <View style={styles.overlay} />
            }
            <View style={styles.title} />
            <AnimatedView
              style={styles.throbber}
              animation={{
                from: {
                  transform: [
                    // { scaleX: .1 },
                    { translateX: -(windowWidth) },
                  ]
                },
                to: {
                  transform: [
                    // { scaleX: 2 },
                    { translateX: windowWidth }
                  ]
                },

              }}
              iterationCount="infinite"
              duration={2000}
            />
          </View>
        </Modal>
        // </RootPortal>
      );
    }

  }
);
const styles = StyleSheet.create({
  progressIndicator: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: "hidden"
  },
  visible: {
    opacity: 1
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 6,
    backgroundColor: StyleSheet.color(Colors.background).rgba(.5)
  },
  title: {
    display: "none"
  },
  throbber: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    // right: 0,
    // left: 0,
    zIndex: 6,
    height: 4,
    backgroundColor: Colors.primary,
  }
});
export default ProgressIndicator;