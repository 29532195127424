import R14, { AsyncStorage, AsyncSessionStorage } from "../core";

export default class userSessionDomain extends R14.Domain {
  constructor() {
    super();
    this.AUTH_STATE_LOGGED_OUT = "LOGGED_OUT";
    this.AUTH_STATE_LOGGED_IN = "LOGGED_IN";
    this.AUTH_STATE_VERIFY_MFA_CODE = "VERIFY_MFA_CODE";
    this.state = {
      uid: null,
      username: null,
      name: null,
      clientUid: null,
      clientName: null,
      role: null,
      loggedIn: false,
      manualEntryConfigUid: null,
      virtualWorkspaceConfigUid: null,
      permissions: {},
      settings: {},
      metrics: {},
      authState: this.STATE_LOGGED_OUT,
      mfaAccessToken: null,
    };
    this._userMetricsSubscription = null;
    // this._metrics = null;
  }
  async domainDidLoad() {
    return await this.load();
  }
  async load() {
    let promises = [];
    let loggedIn = await this.api.accessTokenExists();

    if (loggedIn) {
      try {
        await this.authR14Login();
      } catch (err) {
        console.error(err);
        this.redirectToR14Login();
      }
      return true;
    }

    let userState = {
      uid: (await AsyncStorage.getItem("uid")) || "",
      username: (await AsyncStorage.getItem("username")) || "",
      email: (await AsyncStorage.getItem("email")) || "",
      name: (await AsyncStorage.getItem("name")) || "",
      clientUid: (await AsyncStorage.getItem("clientUid")) || "",
      clientName: (await AsyncStorage.getItem("clientName")) || "",
      manualEntryConfigUid:
        (await AsyncStorage.getItem("manualEntryConfigUid")) || "",
      virtualWorkspaceConfigUid:
        (await AsyncStorage.getItem("virtualWorkspaceConfigUid")) || "",
      clientName: (await AsyncStorage.getItem("clientName")) || "",
      loggedIn,
      mfaAccessToken:
        (await AsyncSessionStorage.getItem("mfaAccessToken")) || "",
      permissions: {},
    };

    // for (let i in userState) {
    //   promises.push(userState[i]);
    // }
    // await Promise.all(promises);

    // Calculate the auth state
    userState.authState = loggedIn
      ? this.AUTH_STATE_LOGGED_IN
      : userState.mfaAccessToken
      ? this.AUTH_STATE_VERIFY_MFA_CODE
      : this.AUTH_STATE_LOGGED_OUT;

    if (userState.loggedIn) {
      await this.initUserSettings(userState.uid);
      await this.initUserMetrics(userState.uid);
    }
    this.setState(userState);
    return true;
  }
  async handleAuthError() {
    await this.logout();
    if (this.redirectToR14Login()) return false;
    else this.nav.load("login", {});
  }
  async initUserSettings(uid) {
    let settings = (await AsyncStorage.getItem(`userSettings${uid}`)) || null;
    settings = settings ? (settings = JSON.parse(settings)) : {};
    this.setState({ settings: settings });
  }
  async setUserSetting(key, value) {
    if (!this.isLoggedIn) return null;
    let settings = this.state.settings;
    settings[key] = value;
    AsyncStorage.setItem(`userSettings${this.uid}`, JSON.stringify(settings));
    this.setState({ settings });
  }
  getUserSetting(key, value) {
    if (!this.isLoggedIn) return null;
    return key in this.state.settings ? this.state.settings[key] : null;
  }
  async initUserMetrics(manualEntryUserUid) {
    let qryArgs = { uid: manualEntryUserUid };
    let res = await this.api.qry(
      `
          query ManualEntryUserMetrics($uid: ID!) {
            manualEntryUser(uid: $uid){
              uid
              metrics {
                mode
                type
                aggregateType
                value
                rate
              }
            }
         }`,
      qryArgs
    );
    this.setState({
      metrics:
        res.data && res.data.manualEntryUser && res.data.manualEntryUser.metrics
          ? res.data.manualEntryUser.metrics
          : [],
    });
  }
  async onUpdate(manualEntryUserUid, callback) {
    //if (!this.isLoggedIn) return null;
    return await this.api.subscribe(
      `
      subscription OnUpdateEntryUserMetrics($uid: ID!) {
        onUpdateManualEntryUser(uid: $uid){
          uid
          metrics {
            mode
            type
            aggregateType
            value
            rate
          }
        }
      }`,
      {
        uid: manualEntryUserUid,
      },
      (res) => {
        // This seams kind of hack city.
        this.setMetrics(res.data.onUpdateManualEntryUser.metrics);
        callback(res.data.onUpdateManualEntryUser);
      }
    );
  }
  // MFA Code Verification
  async resendMfaCode() {
    let mfaAccessToken = this.mfaAccessToken;
    if (!mfaAccessToken) throw new Error("Token not found.");
    let res = await this.api.mutate(
      `
        mutation ResendManualEntryUserMfaCode {
          resendManualEntryUserMfaCode {
            success
            error
          }
       }`,
      {},
      { accessToken: mfaAccessToken }
    );
    return true;
  }
  // MFA Code Verification
  async verifyMfaCode({ mfaCode }) {
    let mfaAccessToken = this.mfaAccessToken;
    if (!mfaAccessToken) throw new Error("Token not found.");
    let res = await this.api.mutate(
      `
        mutation VerifyManualEntryUserMfaCode($input: VerifyManualEntryUserMfaCodeInput!) {
          verifyManualEntryUserMfaCode(input: $input){
            manualEntryUser {
              uid
              name
              username
              email
              client {
                uid
                name
              }
              metrics {
                mode
                type
                aggregateType
                value
                rate
              }
              manualEntryConfigUid
              virtualWorkspaceConfigUid
            }
            state
            accessToken
            success
            error
          }
       }`,
      {
        input: {
          mfaCode,
        },
      },
      { accessToken: mfaAccessToken }
    );
    if (
      res.data &&
      res.data.verifyManualEntryUserMfaCode &&
      res.data.verifyManualEntryUserMfaCode.error
    )
      throw new Error(res.data.verifyManualEntryUserMfaCode.error);
    else if (res.error && res.errors.length) {
      throw new Error(res.error[1]);
    } else if (!res.data.verifyManualEntryUserMfaCode) {
      throw new Error("Unknown error");
    }
    return await this._processAuthResult(res.data.verifyManualEntryUserMfaCode);
  }
  async logoutR14Login() {
    let res = await this.api.mutate(
      `
        mutation LogoutR14LoginUser {
          logoutR14LoginUser {
            success
            error
          }
        }`,
      {},
      { ignoreAccessTokenRefresh: true, accessToken: false }
    );
    if (
      res.data &&
      res.data.logoutR14LoginUser &&
      res.data.logoutR14LoginUser.error
    )
      throw new Error(res.data.logoutR14LoginUser.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);
    return res.data.logoutR14LoginUser;
  }
  async authR14Login() {
    let res = await this.api.mutate(
      `
        mutation AuthR14LoginUser {
          authR14LoginUser {
            manualEntryUser {
              uid
              name
              username
              email
              client {
                uid
                name
              }
              metrics {
                mode
                type
                aggregateType
                value
                rate
              }
              manualEntryConfigUid
              virtualWorkspaceConfigUid
            }
            state
            accessToken
            accessTokenExpiresMinutes
            success
            error
          }
       }`,
      {},
      { ignoreAccessTokenRefresh: true, accessToken: false }
    );
    let error = null;
    if (
      res.data &&
      res.data.authR14LoginUser &&
      res.data.authR14LoginUser.error
    )
      error = res.data.authR14LoginUser.error;
    else if (res.errors && res.errors.length) error = res.errors[0].message;
    if (error) console.error(error);
    if (!res.data.authR14LoginUser) throw new Error("No user found");
    return await this._processAuthResult(res.data.authR14LoginUser);
  }
  async auth({ username, password }) {
    await this.logout();
    let res = await this.api.mutate(
      `
        mutation AuthManualEntryUser($input: AuthManualEntryUserInput!) {
          authManualEntryUser(input: $input){
            manualEntryUser {
              uid
              name
              username
              email
              client {
                uid
                name
              }
              metrics {
                mode
                type
                aggregateType
                value
                rate
              }
              manualEntryConfigUid
              virtualWorkspaceConfigUid
            }
            state
            accessToken
            success
            error
          }
       }`,
      {
        input: {
          username: username,
          password: password,
        },
      }
    );
    if (
      res.data &&
      res.data.authManualEntryUser &&
      res.data.authManualEntryUser.error
    )
      throw new Error(res.data.authManualEntryUser.error);
    else if (res.error && res.errors.length) {
      throw new Error(res.errors);
    } else if (!res.data.authManualEntryUser) {
      throw new Error("Unknown error");
    }
    return await this._processAuthResult(res.data.authManualEntryUser);
  }
  async _processAuthResult(res) {
    if (
      !res.manualEntryUser ||
      !res.accessToken ||
      !res.state ||
      res.state === this.AUTH_STATE_LOGGED_OUT
    ) {
      if (this.isLoggedIn) await this.logout();
      throw new Error("Unknown Auth Error: User not logged in.");
    }
    let manualEntryUser = res.manualEntryUser;
    if (!manualEntryUser || !manualEntryUser.uid)
      new Error("Unknown Auth Error: Manual Entry User not found.");
    if (!manualEntryUser.client || !manualEntryUser.client.uid)
      new Error("Unknown Auth Error: Client not found.");

    switch (res.state) {
      case this.AUTH_STATE_LOGGED_IN:
        await this.api.setAccessToken(res.accessToken);
        if (!(await this.api.accessTokenExists()))
          throw new Error("Access token not found");
        if (res.accessTokenExpiresMinutes) {
          let expiresAt = new Date();
          expiresAt = expiresAt.setMinutes(
            expiresAt.getMinutes() + res.accessTokenExpiresMinutes
          );
          this.api.setAccessTokenRefresh(expiresAt, async () => {
            try {
              await this.authR14Login();
            } catch (err) {
              console.error(err);
              this.redirectToR14Login();
            }
            return true;
          });
        }
        this.setUid(manualEntryUser.uid);
        this.setName(manualEntryUser.name);
        this.setEmail(manualEntryUser.email);
        this.setUsername(manualEntryUser.username);
        this.setMetrics(manualEntryUser.metrics);
        this.setClientUid(manualEntryUser.client.uid);
        this.setClientName(manualEntryUser.client.name);
        this.setVirtualWorkspaceUid(manualEntryUser.virtualWorkspaceConfigUid);
        this.setManualEntryConfigUid(manualEntryUser.manualEntryConfigUid);
        this.setLoggedIn(true);
        this.setAuthState(this.AUTH_STATE_LOGGED_IN);
        await this.deleteMfaAccessToken();
        break;
      case this.AUTH_STATE_VERIFY_MFA_CODE:
        await this.api.deleteAccessToken();
        if (!res.accessToken) throw new Error("Verify access token not found.");
        this.setUid(manualEntryUser.uid);
        this.setName(manualEntryUser.name);
        this.setEmail(manualEntryUser.email);
        this.setUsername(manualEntryUser.username);
        this.setClientUid(manualEntryUser.client.uid);
        this.setClientName(manualEntryUser.client.name);
        this.setLoggedIn(false);
        this.setAuthState(this.AUTH_STATE_VERIFY_MFA_CODE);
        this.setMfaAccessToken(res.accessToken);
        break;
      default:
        throw new Error("Unknown authentication state.");
    }
    return res.state || this.AUTH_STATE_LOGGED_OUT;
  }
  async logout(options = {}) {
    let loggedIn = this.isLoggedIn ? true : false;
    await this.api.deleteAccessToken();
    this.setLoggedIn(false);
    this.setMetrics(null);
    await this.unsubscribe();
    await this.ui.documentSet.clearInstances();
    await this.dm.manualEntry.reset();
    await AsyncStorage.clear();
    await AsyncSessionStorage.clear();
    if (loggedIn && options.logoutR14Login !== false)
      await this.logoutR14Login();
    return true;
  }
  async subscribe() {
    if (!this.isLoggedIn || this._userMetricsSubscription) return true;
    this._userMetricsSubscription = await this.onUpdate(
      this.uid,
      (manualEntryUser) => {
        this.setState({ userMetrics: manualEntryUser.metrics || [] });
      }
    );
  }
  async unsubscribe() {
    this._userMetricsSubscription &&
      this._userMetricsSubscription.unsubscribe &&
      this._userMetricsSubscription.unsubscribe();
    this._userMetricsSubscription = null;
  }
  async setUid(uid) {
    AsyncStorage.setItem("uid", uid);
    this.setState({ uid: uid });
  }
  get uid() {
    return this.state.uid;
  }
  get metrics() {
    return this.state.metrics;
  }
  setMetrics(metrics) {
    this.setState({ metrics: metrics });
  }
  setName(name) {
    AsyncStorage.setItem("name", name || "");
    this.setState({ name: name });
  }
  get name() {
    return this.state.name;
  }
  setEmail(email) {
    AsyncStorage.setItem("email", email || "");
    this.setState({ email: email });
  }
  get email() {
    return this.state.email;
  }
  setUsername(username) {
    AsyncStorage.setItem("username", username || "");
    this.setState({ username: username });
  }
  get username() {
    return this.state.username;
  }
  setClientUid(clientUid) {
    AsyncStorage.setItem("clientUid", clientUid);
    this.setState({ clientUid: clientUid });
  }
  get clientUid() {
    return this.state.clientUid;
  }
  setClientName(clientName) {
    AsyncStorage.setItem("clientName", clientName);
    this.setState({ clientName: clientName });
  }
  get clientName() {
    return this.state.clientName;
  }
  setLoggedIn(loggedIn) {
    this.setState({
      loggedIn: loggedIn,
    });
  }
  get isLoggedIn() {
    return this.state.loggedIn;
  }
  get manualEntryConfigUid() {
    return this.state.manualEntryConfigUid;
  }
  setManualEntryConfigUid(manualEntryConfigUid) {
    AsyncStorage.setItem("manualEntryConfigUid", manualEntryConfigUid || "");
    this.setState({ manualEntryConfigUid });
  }
  get authState() {
    return this.state.authState;
  }
  setAuthState(authState) {
    this.setState({ authState });
  }
  get mfaAccessTokenExists() {
    return this.state.mfaAccessToken ? true : false;
  }
  get mfaAccessToken() {
    return this.state.mfaAccessToken;
  }
  setMfaAccessToken(mfaAccessToken) {
    AsyncSessionStorage.setItem("mfaAccessToken", mfaAccessToken || "");
    this.setState({ mfaAccessToken });
  }
  async deleteMfaAccessToken() {
    await AsyncSessionStorage.removeItem("mfaAccessToken");
    this.setState({ mfaAccessToken: undefined });
  }
  get manualEntryConfigExists() {
    return this.manualEntryConfigUid ? true : false;
  }
  get virtualWorkspaceConfigUid() {
    return this.state.virtualWorkspaceConfigUid;
  }
  setVirtualWorkspaceUid(virtualWorkspaceConfigUid) {
    AsyncStorage.setItem(
      "virtualWorkspaceConfigUid",
      virtualWorkspaceConfigUid || ""
    );
    this.setState({ virtualWorkspaceConfigUid });
  }
  get virtualWorkspaceConfigExists() {
    return this.virtualWorkspaceConfigUid ? true : false;
  }
  redirectToR14Login(path = "", params = {}) {
    // alert("REMOVE FORWARD");
    // return false;
    if (!path && process.env.NODE_ENV === "development") {
      console.log("R14 FORWARD DISABLED FOR DEV.");
      return false;
    }
    if (typeof path === "object") {
      params = path;
      path = "";
    }
    if (
      this.r14.config &&
      this.r14.config.metadata &&
      this.r14.config.metadata.r14Login &&
      this.r14.config.metadata.r14Login.url
    ) {
      this.nav.toUrl(`${this.r14.config.metadata.r14Login.url}${path}`);
      return true;
    }
    return false;
  }
}
