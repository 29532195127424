import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "../StyleSheet";
import View from "../View";
import AnimatedView from "../AnimatedView";
import IconButton from "../IconButton";
import { Colors } from "../Theme";
import ReCAPTCHA from "react-google-recaptcha";

export default class ReCaptchaBass extends React.PureComponent {
  static propTypes = {
    /** If true the user won't be able to toggle the checkbox. Default value is false. */
    disabled: PropTypes.bool,
    /** The Google site key value. */
    siteKey: PropTypes.string,
    /** Invoked with the new value when the value changes. */
    onValueChange: PropTypes.func,
  };
  static defaultProps = {
    value: false,
    theme: "light",
  };
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleValueChange = this.handleValueChange.bind(this);
  }
  componentDidMount() {
    if (this.props.autoFocus) this.autoFocus();
  }
  autoFocus() {
    setTimeout(() => {
      if (!this.elmt.ref.current) return;
      this.ref.current.focus();
    }, 350);
  }
  handleValueChange(value) {
    if (this.props.onValueChange) this.props.onValueChange(value);
  }
  render() {
    let styles = this.styles();
    return (
      <View style={styles.reCaptcha}>
        <ReCAPTCHA
          sitekey={this.props.siteKey}
          onChange={this.handleValueChange}
          theme={this.props.theme}
        />
      </View>
    );
  }
  styles() {
    return StyleSheet.create({
      reCaptcha: {},
    });
  }
}
