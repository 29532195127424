import React from "react";
import R14, { Redirect } from "./core";
import ManualEntryScreen from "./screens/ManualEntryScreen";
import ManualEntryDocumentSetScreen from "./screens/ManualEntryDocumentSetScreen";
import ManualEntryReviewScreen from "./screens/ManualEntryReviewScreen";
import ManualEntryRefreshScreen from "./screens/ManualEntryRefreshScreen";
import ManualEntryIncompatibleScreen from "./screens/ManualEntryIncompatibleScreen";
import HistoryScreen from "./screens/HistoryScreen";
import LoginScreen from "./screens/LoginScreen";
import LoginVerifyScreen from "./screens/LoginVerifyScreen";
import ServicesScreen from "./screens/ServicesScreen";
import ManualEntryDocumentSetCompleteScreen from "./screens/ManualEntryDocumentSetCompleteScreen.js";
import UserSessionConfirmScreen from "./screens/UserSessionConfirmScreen";
import NdaScreen from "./screens/NdaScreen";

import ManualEntryDocumentSetDocumentScreen from "./screens/ManualEntryDocumentSetDocumentScreen";
import ManualEntryDocumentSetAnnotationEditScreen from "./screens/ManualEntryDocumentSetAnnotationEditScreen";
import VirtualWorkspaceScreen from "./screens/VirtualWorkspaceScreen";
import AccountSettingsScreen from "./screens/AccountSettingsScreen";
import R14LicenseAdminConfigEditScreen from "./lib/screens/R14LicenseAdminConfigEditScreen";

//import Bowser from "bowser";

export default class Actions extends R14.Actions {
  async shouldActionLoad({ to, from, app }) {
    // Only Chrome for now.
    // const browser = Bowser.getParser(window.navigator.userAgent);
    // if (browser.getBrowserName() !== "Chrome" && to.name !== "incompatible") {
    //   return <Redirect to='incompatible' />;
    // }

    if (to.metadata.public) return true;

    // If not logged int, forward to login
    if (!app.dm.userSession.isLoggedIn) {
      if (this.dm.userSession.redirectToR14Login()) return false;
      return <Redirect to='login' />;
    }
    // if (to.metadata.permissions) {
    //   let permissions = to.metadata.permissions;
    //   if (!permissions.type)
    //     throw new Error("Route Permissions Error: No type found.");
    //   if (
    //     !this.dm.user.checkPermissions(
    //       permissions.type,
    //       permissions.subtype || null,
    //       permissions.access
    //         ? permissions.access
    //         : this.dm.user.PERMISSION_ACCESS_READ
    //     )
    //   )
    //     return <UserPermissionsErrorScreen to='userPermissionsError' />;
    // }
    return true;
  }
  /** User login actions */
  async landing({ f = null }) {
    let to = null;
    if (f) {
      to = this.nav.getRouteByPath(f);
      if (["login", "loginVerify", "landing"].includes(to.name)) to = null;
    }
    if (!to) {
      to = "login";
      if (this.dm.userSession.virtualWorkspaceConfigExists) {
        to = "services";
      } else if (this.dm.userSession.manualEntryConfigExists)
        to = "manualEntryLoad";
      else
        this.ui.snackBar.show({
          message: "No services found",
          variant: "error",
        });
    }

    // let to = "login";
    // if (this.dm.userSession.virtualWorkspaceConfigExists) {
    //   to = "services";
    // } else if (this.dm.userSession.manualEntryConfigExists)
    //   to = "manualEntryLoad";
    // else
    //   this.ui.snackBar.show({
    //     message: "No services found",
    //     variant: "error",
    //   });
    return <Redirect to={to} />;
  }
  /** Auth from r14 login app */
  async auth({ f = null }) {
    if (
      this.dm.userSession.authState !== this.dm.userSession.AUTH_STATE_LOGGED_IN
    )
      try {
        await this.dm.userSession.authR14Login();
      } catch (err) {
        console.error(err);
      }
    if (
      this.dm.userSession.authState !== this.dm.userSession.AUTH_STATE_LOGGED_IN
    ) {
      if (this.dm.userSession.redirectToR14Login()) return null;
      return <Redirect to='login' />;
    }
    return <Redirect to='landing' params={f ? { f } : null} />;
  }
  async r14LicenseAdminConfigEdit() {
    return <R14LicenseAdminConfigEditScreen />;
  }
  async login({ f = null }) {
    if (this.dm.userSession.redirectToR14Login()) return null;
    // console.log("CHECK forward and figure out route.", f);
    // Check for redirect after login
    // let redirectRoute = f ? this.nav.getRouteByPath(f) : null;
    throw new Error("Login Screen has been disabled.");
    return <LoginScreen f={f} />;
  }
  async loginVerify({ f = null }) {
    if (this.dm.userSession.redirectToR14Login()) return null;
    // Check that it is the correct auth state
    if (
      this.dm.userSession.authState !==
        this.dm.userSession.AUTH_STATE_VERIFY_MFA_CODE ||
      !this.dm.userSession.mfaAccessTokenExists
    )
      return <Redirect to='login' />;

    return <LoginVerifyScreen f={f} />;
  }
  async history() {
    return <HistoryScreen />;
  }
  async incompatible() {
    return <ManualEntryIncompatibleScreen />;
  }
  async logout() {
    await this.dm.userSession.logout();
    if (this.dm.userSession.redirectToR14Login()) return null;
    return <Redirect to='login' />;
  }
  /** Account Actions */
  async accountSettings() {
    let formData = await this.dm.user.fetchAccountSettingsFormData(
      this.dm.userSession.uid
    );
    return <AccountSettingsScreen formData={formData} />;
  }
  async services() {
    let services = await this.dm.user.fetchServiceInfo(this.dm.userSession.uid);
    return <ServicesScreen services={services} />;
  }
  async manualEntryLoad() {
    await this.dm.manualEntry.update();
    let ret = (
      <Redirect
        to='manualEntry'
        params={{ queueItemNum: this.dm.manualEntry.queueIdx + 1 }}
      />
    );
    if (
      this.dm.manualEntry.ndaAcceptRequired &&
      !this.dm.manualEntry.ndaAccepted
    )
      return <Redirect to='nda' />;
    else if (this.dm.manualEntry.shouldConfirmUserSession)
      return <Redirect to='userSessionConfirm' />;
    else if (!this.dm.manualEntry.queueLength)
      ret = <Redirect to='manualEntryRefresh' />;
    else if (
      this.dm.manualEntry.mode ===
        this.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS &&
      this.dm.manualEntry.documentSetUid
    )
      ret = (
        <Redirect
          to='manualEntryDocumentSet'
          params={{ uid: this.dm.manualEntry.documentSetUid }}
        />
      );
    else if (this.dm.manualEntry.isQueueProcessed) {
      ret = <Redirect to='manualEntryReview' />;
    }
    return ret;
  }
  async manualEntryTimeout() {
    return <Redirect to='manualEntryTimeoutRefresh' />;
  }
  async manualEntryTimeoutRefresh() {
    return (
      <ManualEntryRefreshScreen
        buttonTitle='Continue'
        message='Your queue has timed out. Press continue to keep going.'
      />
    );
  }
  async manualEntryReview() {
    if (!this.dm.manualEntry.queueLength)
      return <Redirect to='manualEntryLoad' />;
    return <ManualEntryReviewScreen />;
  }
  async manualEntryRefresh() {
    return <ManualEntryRefreshScreen />;
  }
  async manualEntry({ queueItemNum }) {
    let item = await this.dm.manualEntry.fetchQueueItem({
      idx: parseInt(queueItemNum) - 1,
    });
    if (!item) return <Redirect to='manualEntryLoad' />;
    return <ManualEntryScreen item={item} />;
  }
  async manualEntryDocumentSet({ uid }) {
    let documentSet = await this.ui.documentSet.instance(uid);
    if (!documentSet) return <Redirect to='manualEntryLoad' />;
    return <ManualEntryDocumentSetScreen documentSet={documentSet} />;
  }
  async manualEntryDocumentSetDocumentReload(params) {
    return <Redirect to='manualEntryDocumentSetDocument' params={params} />;
  }
  async manualEntryDocumentSetDocument({ uid, pageNumber }) {
    let documentSet = await this.ui.documentSet.instance(uid);
    if (!documentSet) return null;

    let documentsPage =
      Math.floor(
        (pageNumber - 1) / documentSet.documentImagesPageInfo.resultsPerPage
      ) + 1;

    if (documentsPage !== documentSet.documentImagesPageInfo.page)
      await documentSet.loadDocuments({
        page: documentsPage,
      });

    let page = documentSet.getPage(pageNumber, { current: true });

    return (
      <ManualEntryDocumentSetDocumentScreen
        documentSet={documentSet}
        page={page}
      />
    );
  }
  async manualEntryDocumentSetComplete({ uid }) {
    let documentSet = await this.ui.documentSet.instance(uid);
    if (!documentSet) return <Redirect to='manualEntryLoad' />;
    return <ManualEntryDocumentSetCompleteScreen documentSet={documentSet} />;
  }
  async manualEntryDocumentSetAnnotationEdit({ uid, annotationUuid }) {
    let documentSet = await this.ui.documentSet.instance(uid);
    if (!documentSet) return null;
    //await this.utils.core.sleep(3000);
    let annotation = documentSet.getAnnotation(annotationUuid, {
      current: true,
    });

    if (!documentSet.documentLoaded(annotation.documentUuid)) {
      await documentSet.loadDocuments({
        documentUuid: annotation.documentUuid,
      });
      // Get the annotation incase it has updated
      annotation = documentSet.getAnnotation(annotationUuid, {
        current: true,
      });
    }

    // if (!documentSet) return <Redirect to='manualEntryLoad' />;
    let formData = annotation.initEditForm();
    // let formData = {
    //   title: annotation.name,
    //   annotation,
    //   values: {
    //     value: annotation.value,
    //   },
    // };
    let page = documentSet.getPage(annotation.pageNumber);

    if (
      !documentSet.currentPage ||
      documentSet.currentPage.number !== page.number
    ) {
      documentSet.navToPage(annotation.pageNumber, { replace: true });
    }

    return (
      <ManualEntryDocumentSetAnnotationEditScreen
        page={page}
        annotation={annotation}
        documentSet={documentSet}
        formData={formData}
      />
    );
  }
  async userSessionConfirm() {
    let manualEntryUserSession = await this.dm.manualEntry.fetchUserSession();
    return (
      <UserSessionConfirmScreen
        manualEntryUserSession={manualEntryUserSession}
      />
    );
  }
  async nda() {
    return <NdaScreen />;
  }
  async virtualWorkspace({ uid, virtualWorkspaceConfigUid }) {
    let sessionInfo = await this.dm.virtualWorkspace.fetchSessionInfo(
      virtualWorkspaceConfigUid,
      uid
    );
    return (
      <VirtualWorkspaceScreen
        url={sessionInfo.url}
        uid={uid}
        virtualWorkspaceConfigUid={virtualWorkspaceConfigUid}
      />
    );
  }

  // Admin Actions
  async adminUsers() {
    return null;
  }
}
