export default class AsyncSessionStorageBase{
  static async setItem(key, val){
    return sessionStorage.setItem(key, val);
  }
  static async getItem(key){
    return sessionStorage.getItem(key);
  }
  static async removeItem(key){
    return sessionStorage.removeItem(key);
  }
  static async clear(){
    return sessionStorage.clear();
  }
}
