
import React from 'react';
import R14 from '../R14';
import Button from './Button';

const SubmitButton = R14.connectForm(
    class SubmitButton extends React.Component {
        constructor(props){
          super(props);
          this.handlePress = this.handlePress.bind(this);
          this.backgroundColor = this.props.backgroundColor || this.props.form.activeColor;
        }
        handlePress(){
          this.props.form.submit();
        }
        render() {
          return (
            <Button 
              tabIndex={0}
              backgroundColor={this.backgroundColor} 
              {...this.props} 
              onPress={this.handlePress}
              type="submit"
            />
          );
        }
      }
);
export default SubmitButton;