import React from "react";
import PropTypes from "prop-types";
import { Provider } from "mobx-react";
import R14 from "../R14";
import Touchable from "./Touchable";
import View from "./View";
import StyleSheet from "./StyleSheet";

export default R14.connect(
  class DraggableContainer extends React.Component {
    static propTypes = {
      /** The name for the container */
      name: PropTypes.string.isRequired,
    };
    // static defaultProps = {
    // };
    constructor(props) {
      super(props);
      this.ref = React.createRef();
      this.handleLayout = this.handleLayout.bind(this);
      this.handlePressMove = this.handlePressMove.bind(this);
      this.handlePress = this.handlePress.bind(this);
      this.handlePressOut = this.handlePressOut.bind(this);
      this.handlePressIn = this.handlePressIn.bind(this);
      this.handlePressCancel = this.handlePressCancel.bind(this);
      this.draggable = this.props.app.ui.draggable.create(this.props);
      // this.state = {
      //   x: 0,
      //   y: 0,
      //   dragging: false,
      // };
    }
    handlePress(event) {
      this.props.onPress && this.props.onPress(event);
    }
    handlePressMove(event) {
      this.props.onPressMove && this.props.onPressMove(event);
      this.draggable.active && this.draggable.handlePressMove(event);
    }
    handlePressOut(event) {
      this.props.onPressOut && this.props.onPressOut(event);
      //this.draggable.active && this.draggable.handlePressOut(event);
    }
    handlePressIn(event) {
      this.props.onPressIn && this.props.onPressIn(event);
      //this.draggable.active && this.draggable.handlePressIn(event);
    }
    handlePressCancel(event) {
      this.props.onPressCancel && this.props.onPressCancel(event);
      this.draggable.active && this.draggable.handlePressCancel(event);
    }
    handleLayout(event) {
      this.measure((offsetLeft, offsetTop, width, height, left, top) =>
        this.draggable.handleMeasure({
          height: height,
          width: width,
          x: left,
          y: width,
        })
      );
      if (this.props.onLayout) this.props.onLayout(event);
    }
    measure(callback) {
      this.ref.current.measure(callback);
    }
    render() {
      return (
        <Provider draggableContainer={this.draggable}>
          {/* <View > */}
          <Touchable
            onLayout={this.handleLayout}
            ref={this.ref}
            feedback={false}
            // onPressOut={this.handlePressOut}
            onPress={this.props.onPress && this.handlePress}
            onPressIn={this.props.onPressIn && this.handlePressIn}
            onPressOut={this.props.onPressOut && this.handlePressOut}
            onPressMove={this.handlePressMove}
            onPressCancel={this.handlePressCancel}
            style={[styles.draggableContainer, this.props.style]}
          >
            {this.props.children}
          </Touchable>
          {/* </View> */}
        </Provider>
      );
    }
  }
);
const styles = StyleSheet.create({
  draggableContainer: {
    position: "relative",
    flex: 1,
    flexDirection: "row",
    cursor: null,
  },
});
