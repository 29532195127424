import React from "react";
import R14, {
  Button,
  View,
  Text,
  Colors,
  StyleSheet,
  ScrollView,
  Surface,
  ZoomableView,
  SideSheet,
  ScreenLayout,
  Scroller,
  Image,
  NavigationContainer,
  Dialog,
} from "../core";

import DocumentSetPageScroller from "../components/DocumentSetPageScroller";
import DocumentSetFieldScroller from "../components/DocumentSetFieldScroller";
import CountdownTimer from "../components/CountdownTimer";

export default R14.connect(
  class ManualEntryDocumentSetScreen extends React.Component {
    constructor(props) {
      super(props);
      // this.handleLayout = this.handleLayout.bind(this);
      // this.handleScreenLayout = this.handleScreenLayout.bind(this);
      this.handleDocumentCompleteDialogClosePress =
        this.handleDocumentCompleteDialogClosePress.bind(this);
      this.handleDocumentCompleteDialogOkPress =
        this.handleDocumentCompleteDialogOkPress.bind(this);
      this.handleDocumentCompleteDialogOkLogoutPress =
        this.handleDocumentCompleteDialogOkLogoutPress.bind(this);
      this.initialValues = this.props.documentSet.initInitialValues();
      this._saving = false;
    }
    // get page(){
    //   return this.props.page;
    // }
    componentDidMount() {
      this.props.app.dm.userSession.unsubscribe();
    }
    componentWillUnmount() {
      this.keyMapper && this.keyMapper.disconnect();
    }

    get page() {
      return this.props.documentSet.currentPage;
    }

    // handleScreenLayout(event) {
    //   console.log("handleScreenLayout", event);
    //   this.page && this.page.updateScreenLayout(event.nativeEvent.layout);
    // }
    // handleLayout(event) {
    //   console.log("handleLayout", event);
    //   this.page && this.page.updateLayout(event.nativeEvent.layout);
    // }

    async handleKeyDown(e) {
      let key = e.key || e.nativeEvent.key;
      if (!key) return false;
      if (!this.keyMappings[key]) return;
      e.preventDefault();
      switch (this.keyMappings[key]) {
        case "refresh":
          this.handleRefreshPress();
          break;
      }
    }
    async handleSubmit(manualEntryForm) {
      return this.handleRefreshPress;
    }
    async handleRefreshPress() {
      this.props.app.nav.to("manualEntryLoad");
      return false;
    }
    async handleDocumentCompleteDialogClosePress() {
      this.props.documentSet.setDocumentCompleteDialogVisible(false);
    }
    async handleDocumentCompleteDialogOkPress() {
      await this.save();
    }
    async handleDocumentCompleteDialogOkLogoutPress() {
      await this.save({ logout: true });
    }
    async save(options = {}) {
      await this.props.documentSet.complete({ ...options });
      // if (this._saving) {
      //   return false;
      // }
      // this._saving = true;
      // if (
      //   options.logout &&
      //   !window.confirm("Are you sure you want to save and logout?")
      // )
      //   return false;
      // this.props.app.ui.progressIndicator.show();
      // await this.props.documentSet.complete();
      // this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      // this.props.app.ui.snackBar.show({
      //   message: "Your updates have been submitted.",
      //   variant: "success",
      // });
      // this.props.app.nav.to(options.logout ? "logout" : "manualEntryLoad");
      // this._saving = false;
    }
    getKeyMapping(key) {
      return this.keyMappings[key] ? this.keyMappings[key] : null;
    }
    getKeyByMapping(mapping) {
      let ret = null;
      for (let i in this.keyMappings) {
        if (this.keyMappings[i] === mapping) {
          ret = i;
          break;
        }
      }
      return ret;
    }
    renderMessage() {
      return (
        this.props.message ||
        "No results at this time, please try to refresh in a few minutes."
      );
    }
    renderButtonTitle() {
      return this.props.buttonTitle || "Refresh";
    }
    renderControlsBottomRight() {
      let controls = [];
      controls.push([
        <Button
          title={this.renderButtonTitle()}
          tooltip={`${this.renderButtonTitle()} [${this.getKeyByMapping(
            "refresh"
          )}]`}
          key='refresh'
          style={styles.buttonRowButton}
          color={Colors.secondary}
          onPress={this.handleRefreshPress}
        />,
      ]);
      return controls;
    }
    renderDocumentSetCompleteDialog() {
      let dialogOptions =
        this.props.documentSet.documentCompleteDialogOptions || {};
      let title = "Fields Complete";
      if (dialogOptions) {
        if (dialogOptions.acceptRemaining) title = "Accept Remaining Fields";
        else if (dialogOptions.rejectRemaining)
          title = "Reject Remaining Fields";
      }
      return (
        <Dialog
          name='documentSetCompleteDialog'
          title={title}
          visible={
            this.props.documentSet.documentCompleteDialogVisible || false
          }
          titleControlVariant='back'
          headerControlsRight={[
            <CountdownTimer
              key='timer'
              completeAt={this.props.app.dm.manualEntry.timeoutAt}
              onComplete={this.handleTimeout}
              style={styles.countdownTimer}
            />,
          ]}
          onClosePress={this.handleDocumentCompleteDialogClosePress}
          onCancelPress={this.handleDocumentCompleteDialogOkLogoutPress}
          onBackdropPress={this.handleDocumentCompleteDialogClosePress}
          onBackPress={this.handleDocumentCompleteDialogClosePress}
          onOkPress={this.handleDocumentCompleteDialogOkPress}
          okButtonTitle={"Complete"}
          okButtonVariant={"default"}
          cancelButtonTitle='Complete &amp; Logout'
          cancelButtonColor={Colors.secondary}
          // OkButtonComponent={<SubmitButton title='Create'}
        >
          <Text>
            All fields have been completed. Press complete to save your changes
            and go to the next item in your queue.
          </Text>
        </Dialog>
      );
    }

    render() {
      //console.log("history check page",this.props.documentSet.currentPage);
      // let dynamicStyles = this.createDynamicStyles();
      // let documentScrollerItems = this.props.documentSet.pages.map((page) => {
      //   return (
      //     <View
      //       style={styles.scrollerItem}
      //       // label={"test item"}
      //       // key={"test"}
      //       // to={{
      //       //   route: "manualEntryDocumentSet",
      //       // }}
      //       // icon={(this.props.showIcon !== false && route.icon) || null}
      //     >
      //       <View></View>
      //       <Image
      //         resizeMode='contain'
      //         // autosize
      //         style={[styles.image, dynamicStyles.image]}
      //         height={160}
      //         width={128}
      //         auth
      //         style={styles.documentThumbnailImage}
      //         source={{
      //           uri: page.image.url,
      //         }}
      //       />
      //     </View>
      //   );
      // });
      // console.log(
      //   "CHECK THE DOCUMENT?",
      //   this.props.documentSet.currentPage.name
      // );
      return (
        <ScreenLayout
          style={styles.content}
          // scrollViewStyle={styles.scrollView}
          // onLayout={this.handleScreenLayout}
          // onScrollViewLayout={this.handleLayout}
          // scrollEnabled
          SideSheetComponent={
            // <SideSheet>
            //   <SideSheet.Section>
            //    <DocumentAnnotationEditForm />
            //   </SideSheet.Section>
            //   <SideSheet.Section>
            //     <DocumentSetFieldScroller
            //       documentSet={this.props.documentSet}
            //     />
            //   </SideSheet.Section>
            // </SideSheet>
            <Surface style={styles.sideSheet} elevation={6}>
              {/* <DocumentAnnotationEditForm /> */}
              <NavigationContainer
                name='manualEntryDocumentSetAnnotationContainer'
                style={styles.annotationNavigationContainer}
                initialParams={{
                  uid: this.props.documentSet.uid,
                  annotationUuid: this.initialValues.annotationUuid,
                }}
              />
              <DocumentSetFieldScroller documentSet={this.props.documentSet} />
            </Surface>
          }
          BottomSheetComponent={
            this.props.documentSet.totalPages > 1 ? (
              <DocumentSetPageScroller documentSet={this.props.documentSet} />
            ) : null
            // <Scroller direction='horizontal'>{documentScrollerItems}</Scroller>
          }
        >
          {/* <ScrollView
              // style={styles.scrollView}
              contentStyle={[
                styles.scrollViewContent,
                dynamicStyles.scrollViewContent,
              ]}
              onLayout={this.handleLayout}
              overflow='auto'
            > */}
          <NavigationContainer
            name='manualEntryDocumentSetContainer'
            initialParams={{
              pageNumber: this.initialValues.page.number,
              uid: this.props.documentSet.uid,
            }}
          />
          {/* <Document
            headerTitle={this.props.documentSet.page.name}
            onLayout={this.handleScreenLayout}
            onScrollViewLayout={this.handleLayout}
            page={this.props.documentSet.page}
          /> */}
          {/* </ScrollView> */}
          {this.renderDocumentSetCompleteDialog()}
        </ScreenLayout>
      );
    }
    // createDynamicStyles() {
    //   if(! this.page) return {};
    //   console.log("CHECK VLS", this.page.state.height);
    //   let scale = this.page.state.scale;
    //   let imageHeight = this.page.image.height;
    //   let imageWidth = this.page.image.width;
    //   let dynamicStyles = {};
    //   dynamicStyles.areaSelectionView = {
    //     height: imageHeight * scale * this.page.state.zoomLevel,
    //     width: imageWidth * scale * this.page.state.zoomLevel,
    //   };
    //   dynamicStyles.scrollView = {
    //     ...StyleSheet.padding(this.page.padding),
    //   };
    //   return StyleSheet.create(dynamicStyles);
    // }
  }
);
const styles = StyleSheet.create({
  documentSets: {
    top: 36,
  },
  documentSetSurface: {
    ...StyleSheet.padding(0),
    // ...StyleSheet.margin(8, 32, 0, 32),
    overflow: "hidden",
    elevation: 4,
  },
  screenLayout: {
    ...StyleSheet.margin(0),
    // position: "relative",
  },
  header: {
    marginBottom: 0,
  },
  headerContentAnnotate: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.1),
  },
  content: {
    flex: 1,
    // position: "relative",
    overflow: "hidden",
    flexDirection: "column",
  },
  scrollViewContent: {
    // position: "rel;ative"
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  scrollViewContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
  },
  annotationNavigationContainer: {
    flex: 0,
  },
  // scrollerItem: {
  //   height: 160,
  //   width: 128,
  //   flex: 1,
  //   flexShrink: 0,
  //   ...StyleSheet.padding(8),
  // },
  // scrollView: {
  //   // ...StyleSheet.padding(32),
  //   // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.9),
  //   // marginTop: -16
  // },

  documentThumbnailImage: {
    flex: 1,
    backgroundPosition: "center",
  },
  draggableContainer: {
    flex: 0,
    elevation: 12,
    // ...StyleSheet.margin(16, 32, 32, 32),
    // overflow: "hidden",
  },
  areaSelectionView: {
    position: "absolute",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: StyleSheet.color(Colors.primaryLight).rgba(0.4),
    //backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.05),
  },
  zoomButton: {
    borderRadius: 16,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  zoomButtonActive: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
  createButton: {
    marginRight: 8,
  },
  cancelButton: {
    marginRight: 4,
  },
  countdownTimer: {
    paddingRight: 16,
  },
  sideSheet: {
    flex: 0,
    flexDirection: "column",
    width: 360,
    flexGrow: 0,
    ...StyleSheet.padding(0),
    zIndex: 2,
  },
});
