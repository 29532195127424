import React from "react";
import R14, {
  StyleSheet,
  Touchable,
  View,
  Colors,
  AnimatedView,
} from "../core";

export default class DocumentAnnotation extends React.Component {
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
    this.handleAnimationExit = this.handleAnimationExit.bind(this);
    this.containerRef = React.createRef();
    this.state = {
      animate: this.props.active === true,
    };
  }
  // componentDidUpdate(prevProps) {
  //   let animate = this.props.active && prevProps.active !== true;
  //   console.log("animation ANIMATE ANNOTATION?", {
  //     stateChange: this.props.active && prevProps.active !== true,
  //     sameState: animate !== this.state.animate,
  //     animate,
  //   });
  //   if (animate !== this.state.animate) this.setState({ animate });
  // }
  createDynamicStyles() {
    let dynamicStyles = {
      documentAnnotation: {
        top: this.props.top,
        left: this.props.left,
        height: this.props.height,
        width: this.props.width,
        // backgroundColor: Colors.primary,
        // backgroundColor: StyleSheet.color(
        //   this.documentTemplate.getAnnotationColorByType(
        //     this.documentAnnotation.type
        //   )
        // ).rgba(0.25),
        // borderColor: StyleSheet.color(
        //   this.documentTemplate.getAnnotationColorByType(
        //     this.documentAnnotation.type
        //   )
        // ).rgba(0.5),
      },
    };
    return StyleSheet.create(dynamicStyles);
  }
  handlePress(event) {
    this.props.onPress && this.props.onPress(event, this.props);
  }
  handleAnimationExit() {
    this.setState({ animate: false });
  }
  measureInWindow(callback) {
    this.containerRef.current.measureInWindow((px, py, width, height) => {
      callback(px, py, width, height);
    });
  }
  measure(callback) {
    this.containerRef.current.measure((px, py, width, height) => {
      callback(px, py, width, height);
    });
  }
  render() {
    if(this.props.isFullPage && ! this.props.active) return null;
    let dynamicStyles = this.createDynamicStyles();
    let Element = this.props.onPress ? Touchable : View;
    
    return (
      // <AnimatedView
      //   enter={{
      //     opacity: 0,
      //   }}
      //   exit={{
      //     opacity: 1,
      //   }}
      //   in={this.state.animate}
      //   //onEnter={this.handleAnimationExit}
      //   duration={400}
      //   delay={500}
      //   fillMode='both'
      //   iterationCount={this.props.active ? 4 : 1}
      //   animateOnMount={this.props.active === true}
      //   // style={[

      //   // ]}
      // >
      //   <Touchable
      //     style={[
      //       styles.documentAnnotation,
      //       dynamicStyles.documentAnnotation,
      //       this.props.style,
      //       this.props.active && styles.documentAnnotationActive,
      //       this.props.active && this.props.activeStyle,
      //     ]}
      //     ref={this.containerRef}
      //     tooltip={this.props.name}
      //     onPress={this.props.onPress && this.handlePress}
      //     onLayout={this.props.onLayout || null}
      //   >
      //     <View>{this.props.children}</View>
      //   </Touchable>

      // </AnimatedView>
      <Element
        style={[
          styles.documentAnnotation,
          dynamicStyles.documentAnnotation,
          this.props.style,
          this.props.active && styles.documentAnnotationActive,
          this.props.active && this.props.activeStyle,
        ]}
        ref={this.containerRef}
        tooltip={this.props.name}
        onPress={this.props.onPress && this.handlePress}
        onLayout={this.props.onLayout || null}
      >
        <View>{this.props.children}</View>
      </Element>
    );
  }
}

const styles = StyleSheet.create({
  documentAnnotation: {
    position: "absolute",
    backgroundColor: StyleSheet.color("#666666").rgba(0.2),
  },
  documentAnnotationActive: {
    backgroundColor: StyleSheet.color(Colors.queueItemActive).rgba(0.2),
  },
});
