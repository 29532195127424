import React from "react";
import R14 from "../../R14";
import StyleSheet from "../StyleSheet";
import ResizeObserver from "resize-observer-polyfill";

export default class ViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.htmlRef = this.props.htmlRef || React.createRef();
    this.layoutTimeout = null;
    this.prevLayout = null;
    this.state = {
      styleOnLayout: null,
    };
    if (this.props.onLayout || this.props.styleOnLayout) {
      this.resizeObserver = new ResizeObserver((entries) => {
        this.triggerOnLayout();
      });
    }
  }
  componentDidMount() {
    /** @todo should the view check for layout changes before calling this? Use   observer? */
    if (this.props.onLayout) {
      /** @todo Add more native events */
      // setTimeout(()=>{
      //   // let element = this.htmlRef.current.getBoundingClientRect();
      //   // let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      //   // let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      //   let ret = {
      //     nativeEvent: {
      //       layout: {
      //         x: this.htmlRef.current.offsetLeft,
      //         y: this.htmlRef.current.offsetTop,
      //         width: this.htmlRef.current.offsetWidth,
      //         height: this.htmlRef.current.offsetHeight
      //       }
      //     }
      //   };
      //   this.props.onLayout(ret);
      // },5);
      this.layoutTimeout = setTimeout(() => {
        this.triggerOnLayout();
      }, 5);
      this.resizeObserver.observe(this.htmlRef.current);
    }
  }
  componentWillUnmount() {
    if (this.props.onLayout) {
      this.layoutTimeout && clearTimeout(this.layoutTimeout);
      this.resizeObserver.disconnect();
    }
  }
  triggerOnLayout() {
    let event = { nativeEvent: { layout: {} } };
    if (
      (!this.props.onLayout && !this.props.styleOnLayout) ||
      !this.htmlRef.current
    )
      return false;

    let layout = {
      x: this.htmlRef.current.offsetLeft,
      y: this.htmlRef.current.offsetTop,
      width: this.htmlRef.current.offsetWidth,
      height: this.htmlRef.current.offsetHeight,
    };

    if (
      this.prevLayout &&
      layout.x === this.prevLayout.x &&
      layout.y === this.prevLayout.y &&
      layout.height === this.prevLayout.height &&
      layout.width === this.prevLayout.width
    ) {
      return false;
    }

    event = {
      nativeEvent: {
        layout,
      },
    };

    if (this.props.onLayout) this.props.onLayout(event);
    if (this.props.styleOnLayout) {
      let styleOnLayout = this.props.styleOnLayout(event) || null;
      if (styleOnLayout !== this.state.styleOnLayout) {
        this.setState({ styleOnLayout: styleOnLayout });
      }
    }
    this.prevLayout = layout;
  }
  measureInWindow(callback) {
    let offset = R14.utils.dom.offset(this.htmlRef.current);
    let dimensions = R14.utils.dom.dimensions(this.htmlRef.current);
    callback(offset.left, offset.top, dimensions.width, dimensions.height);
  }
  measure(callback) {
    let offset = R14.utils.dom.offset(this.htmlRef.current);
    let dimensions = R14.utils.dom.dimensions(this.htmlRef.current);
    callback(
      this.htmlRef.current.offsetLeft,
      this.htmlRef.current.offsetTop,
      dimensions.width,
      dimensions.height,
      offset.left,
      offset.top
    );
  }
  render() {
    let htmlTag = this.props.htmlTag || "div";
    htmlTag = htmlTag.toLowerCase();
    let styles = this.styles();
    let props = {
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      onScroll: this.props.onScroll,
      tabIndex: this.props.tabIndex,
      ref: this.htmlRef,
      className: StyleSheet.className([
        this.props.style,
        this.props.pointerEvents && styles.pointerEvents,
        this.props.styleOnLayout && styles.styleOnLayout,
      ]),
    };
    if (this.props.draggable) {
      props.draggable = this.props.draggable;
      if (this.props.onDragStart) props.onDragStart = this.props.onDragStart;
      if (this.props.onDragEnd) props.onDragEnd = this.props.onDragEnd;
    }
    if (this.props.onDragEnter) props.onDragEnter = this.props.onDragEnter;
    if (this.props.onDragLeave) props.onDragLeave = this.props.onDragLeave;
    if (this.props.onDragOver) props.onDragOver = this.props.onDragOver;
    if (this.props.onDrop) props.onDrop = this.props.onDrop;

    if (this.props.onPointerDown)
      props.onPointerDown = this.props.onPointerDown;
    if (this.props.onPointerUp) props.onPointerUp = this.props.onPointerUp;
    if (this.props.onPointerMove)
      props.onPointerMove = this.props.onPointerMove;

    if (this.props.onKeyDown) props.onKeyDown = this.props.onKeyDown;
    if (this.props.onKeyUp) props.onKeyUp = this.props.onKeyUp;

    if (this.props.tooltip) props.title = this.props.tooltip;

    if (this.props.id) props.id = this.props.id;

    switch (htmlTag) {
      case "a":
        if (this.props.onClick) props.onClick = this.props.onClick;
        if (this.props.href) props.href = this.props.href;
        break;
    }
    if (typeof this.props.children === "string") {
      console.error(
        `View Component Error: String '${this.props.children}' passed to View. Please enclose strings in a Text component.`
      );
    }
    let view = null;
    if (this.props.Component) {
      let Component = this.props.Component;
      let componentProps = this.props.ComponentProps || {};
      let componentRef = this.props.ComponentHtmlRefFunction
        ? (ref) => {
            this.htmlRef = this.props.ComponentHtmlRefFunction(ref);
          }
        : this.htmlRef;
      if (this.props.ComponentHtmlRefProp) {
        componentProps.ref = componentProps.ref || null;
        componentProps[this.props.ComponentHtmlRefProp] = componentRef;
      } else componentProps.ref = componentRef;
      view = (
        <Component {...props} ref={null} {...componentProps}>
          {this.props.children}
        </Component>
      );
    } else view = React.createElement(htmlTag, props, this.props.children);

    return view;

    // let ViewComponent = this.props.Component ? < ></React.createElement(
    //   htmlTag,
    //   props,
    //   this.props.children
    // );
    // return ViewComponent;
  }
  styles() {
    if (!this.props.pointerEvents && !this.props.styleOnLayout) return {};
    let styles = {
      pointerEvents: {
        pointerEvents: this.props.pointerEvents,
      },
    };
    if (this.state.styleOnLayout) {
      styles.styleOnLayout = this.state.styleOnLayout;
    }
    return StyleSheet.create(styles);
  }
}
