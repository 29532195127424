import { Platform } from "../core/";
const getBaseUrl = () => {
  if (!window || !window.location) return "";
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) url = `${url}:${window.location.port}`;
  return url;
};
let baseUrl = getBaseUrl();
let devBaseUrl = baseUrl;
// let devBaseUrl = "http://3.15.132.137:3005";
const Config = {
  name: "r14-dev-app",
  api: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
    onAuthError: async (app) => {
      await app.dm.userSession.handleAuthError();
    },
  },
  io: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  metadata: {
    r14Login: {
      url:
        process.env.NODE_ENV === "development"
          ? "http://logindev.teletype.team"
          : "https://login.teletype.team",
    },
  },
};
export default Config;
