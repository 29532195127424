import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import FlatList from "./FlatList";
import View from "./View";
import Text from "./Text";
import Button from "./Button";
import TextInput from "./TextInput";
import IconButton from "./IconButton";
import Touchable from "./Touchable";
import PopUpMenu from "./PopUpMenu";
import Icon from "./Icon";
import StyleSheet from "./StyleSheet";
import Checkbox from "./Checkbox";
import ControlsView from "./ControlsView";
import ActivityIndicator from "./ActivityIndicator";
import SelectMenu from "./SelectMenu";
import AnimatedView from "./AnimatedView";
import Form from "./Form";
import { Colors } from "./Theme";
/**
 *
 * Infinite load list
 *
 */
export default R14.connect(
  class InfiniteList extends React.Component {
    static propTypes = {
      /** The unique name for the infinite list  */
      name: PropTypes.string.isRequired,
      /** Requests items by page / rowsPerPage / filters, function should can return props (page, rowsPerPage, pageData, totalRows, filters)  */
      initializer: PropTypes.func.isRequired,
      /** Requests items by page / rowsPerPage  */
      pageLoader: PropTypes.func.isRequired,
      /** Renders a row, passes row items (row) */
      rowRenderer: PropTypes.func.isRequired,
      /** The total number of rows. Required for showing paginated buttons */
      initialTotalRows: PropTypes.number,
      /** A key to be used to render a result row. If not given, will default to index or row.key */
      rowKeyExtractor: PropTypes.func,
      /** The number of results to display per page.  */
      initialRowsPerPage: PropTypes.number,
      /** Title for the Infinite list  */
      headerTitle: PropTypes.string,
      /** Controls such as buttons, icon buttons, etc... Placed at the right of the header title.*/
      headerControlsRight: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.func
      ]),
      /** Component or render function to be displayed when the list is empty. */
      ListEmptyComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      /** Component or render function to be added to the footer of the list. */
      ListFooterComponent: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func
      ]),
      /** A StyleSheet object, or array of StyleSheet objects to apply to the footer component */
      ListFooterComponentStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
      ]),
      /** Component or render function to be added to the header of the list. */
      ListHeaderComponent: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func
      ]),
      /** A StyleSheet object, or array of StyleSheet objects to apply to the header component */
      ListHeaderComponentStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
      ]),
      /** Calls the given function with row data (row) as a param, should return controls such as buttons, icon buttons. Placed at the right of each row.*/
      rowControlsRight: PropTypes.func,
      /** Calls the given function with row data (row) as a param when a row is pressed.*/
      onRowPress: PropTypes.func,
      /** The initial page to display.  */
      initialPage: PropTypes.number
      //  /** The initial column to sort by.  */
      //  initialSortColumnName: PropTypes.string,
      //  /** The direction to sort by */
      //  initialSortDirection: PropTypes.oneOf(["asc", "desc"])
    };
    static defaultProps = {
      initialPage: 1,
      initialRowsPerPage: 10,
      initialTotalRows: 0,
      //  initialSortDirection: "asc",
      //  initialSelectedRows: [],
      headerControlsRight: []
    };
    constructor(props) {
      super(props);
      this.infiniteList = this.props.app.ui.infiniteList.create(props, this);
      this.keyExtractor = this.keyExtractor.bind(this);
      this.renderRow = this.renderRow.bind(this);
      this.loadNextPage = this.loadNextPage.bind(this);
      this.loadPreviousPage = this.loadPreviousPage.bind(this);
    }
    componentDidMount() {
      this.infiniteList.load();
    }
    componentWillUnmount() {
      this.infiniteList.remove();
    }
    keyExtractor(row, index) {
      return this.infiniteList.keyExtractor(row);
    }
    async loadNextPage() {
      return await this.infiniteList.load(this.infiniteList.page + 1);
    }
    async loadPreviousPage() {
      return await this.infiniteList.load(this.infiniteList.page - 1);
    }
    renderRow({ item, index }) {
      let row = item;
      let colArr = [];
      let rowKey = this.infiniteList.keyExtractor(row);
      let RowComponent = View;
      // If onRowPress is set, use a touchable container
      // if (this.props.onRowPress) {
      //   RowComponent = Touchable;
      //   rowProps.onPress = () => {
      //     this.props.onRowPress(row, this.tableData);
      //   };
      // }
      colArr.push(
        <RowComponent key={rowKey}>
          {this.props.rowRenderer({
            row: row
          })}
        </RowComponent>
      );

      if (this.props.rowControlsRight) {
        let rowControlsRight = this.props.rowControlsRight;
        if (typeof rowControlsRight === "function") {
          rowControlsRight = rowControlsRight(row, this.infiniteList);
        }
        colArr.push(
          <View
            style={[styles.headerColsCell, this.rowControlsRightWidthStyle]}
            key='rowControlsRight'
          >
            <View style={styles.rowControlsRight}>{rowControlsRight}</View>
          </View>
        );
      }
      return <View style={[styles.row]}>{colArr}</View>;
    }
    renderListEmptyComponent() {
      /** @todo move state.hasLoaded into the main render function because it is so important for rendering */
      if (
        !this.infiniteList.state.hasLoaded &&
        this.infiniteList.state.showActivityIndicator
      ) {
        return (
          <ActivityIndicator
            size='large'
            containerStyle={styles.activityIndicator}
          />
        );
      } else if (this.props.ListEmptyComponent) {
        let Component =
          typeof this.props.ListFooterComponent === "function"
            ? this.props.ListEmptyComponent()
            : this.props.ListEmptyComponent;
        return Component;
      } else return <Text style={styles.emptyComponent}>No Results</Text>;
    }
    // renderFooter() {
    //   if (!this.props.ListFooterComponent) return null;
    //   let Component =
    //     typeof this.props.ListFooterComponent === "function"
    //       ? this.props.ListFooterComponent()
    //       : this.props.ListFooterComponent;
    //   return (
    //     <View style={[styles.footer, this.props.ListFooterComponentStyle]}>
    //       {Component}
    //     </View>
    //   );
    // }
    // renderFooter() {
    //   if (!this.props.ListHeaderComponent) return null;
    //   let Component =
    //     typeof this.props.ListHeaderComponent === "function"
    //       ? this.props.ListHeaderComponent()
    //       : this.props.ListHeaderComponent;
    //   return (
    //     <View style={[styles.header, this.props.ListHeaderComponentStyle]}>
    //       {Component}
    //     </View>
    //   );
    // }
    render() {
      let ListEmptyComponent = this.renderListEmptyComponent();
      return (
        <FlatList
          ListHeaderComponent={this.props.ListHeaderComponent}
          ListHeaderComponentStyle={this.props.ListHeaderComponentStyle}
          ListFooterComponent={this.props.ListFooterComponent}
          ListFooterComponentStyle={this.props.ListFooterComponentStyle}
          ListEmptyComponent={ListEmptyComponent}
          data={this.infiniteList.data}
          autoSize={true}
          numColumns={1}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderRow}
          ref={this._flatListRef}
          onLayout={this.handleLayout}
        />
      );
    }
  }
);

const styles = StyleSheet.create({});
