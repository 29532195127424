import React from "react";
import StyleSheet from "../StyleSheet";
import View from "../View";
// import R14 from "../../R14";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import "perfect-scrollbar/perfect-scrollbar.css";

import PerfectScrollbar from "../PerfectScrollbar";

// import SimpleBar from 'simplebar-react';
// import 'simplebar-react/dist/simplebar.min.css';

export default class ScrollViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.lastScrollTop = 0;
    this.scrollEnabled = true;
    this.doResetScroll = false;
    this.htmlRef = React.createRef();
    this.ref = React.createRef();
    this.scrollBarRef = React.createRef();
    this.contentHtmlRef = React.createRef();
    this.handleLayout = this.handleLayout.bind(this);
    this.handleContentSizeChange = this.handleContentSizeChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.lastContentSize = {
      x: null,
      y: null,
    };
  }
  scrollTo(params) {
    if (!this.htmlRef.current) return false;
    let scrollParams = {
      left: params.x || 0,
      top: params.y || 0,
    };
    //     if (params.animated !== false) scrollParams.behavior = "smooth";
    // console.log("check",scrollParams);
    setTimeout(
      () => this.htmlRef.current && this.htmlRef.current.scrollTo(scrollParams),
      1
    );
  }
  scrollToEnd(params = {}) {
    if (!this.htmlRef.current) return false;
    params.y =
      this.htmlRef.current.scrollHeight + this.htmlRef.current.clientHeight ||
      0;
    this.scrollTo(params);
  }
  handleLayout(val) {
    if (this.props.onLayout) return this.props.onLayout(val);
  }
  handleScroll() {
    this.triggerOnScroll();
  }
  triggerOnScroll() {
    if (!this.htmlRef.current) return;
    this.props.onScroll &&
      this.props.onScroll({
        nativeEvent: {
          contentOffset: {
            x: this.htmlRef.current.scrollLeft,
            y: this.htmlRef.current.scrollTop,
          },
          layout: {
            width: this.htmlRef.current.offsetWidth,
            height: this.htmlRef.current.offsetHeight,
          },
          contentSize: {
            width: this.contentHtmlRef.current.scrollWidth,
            height: this.contentHtmlRef.current.scrollHeight,
          },
        },
      });
  }
  measureInWindow(callback) {
    if (this.ref && this.ref.current)
      this.ref.current.measureInWindow(callback);
  }
  measure(callback) {
    if (this.ref && this.ref.current) this.ref.current.measure(callback);
  }
  handleContentSizeChange(val) {
    /** @todo this is not tested */
    let contentSize = {
      width: val.nativeEvent.layout.width,
      height: val.nativeEvent.layout.height,
    };
    if (
      contentSize.width === this.lastContentSize.width &&
      contentSize.height === this.lastContentSize.height
    )
      return;
    this.lastContentSize = contentSize;

    // Update perfect scrollbar
    setTimeout(
      () =>
        this.scrollBarRef &&
        this.scrollBarRef.current &&
        this.scrollBarRef.current._ps.update()
    );

    if (this.props.onContentSizeChange)
      this.props.onContentSizeChange(contentSize.width, contentSize.height);
  }
  get overflow() {
    let overflowX = this.props.overflowX || this.props.overflow || "hidden";
    let overflowY = this.props.overflowY || this.props.overflow || "auto";
    if (this.props.horizontal) {
      overflowX = "auto";
      overflowY = "hidden";
    }
    return { x: overflowX, y: overflowY };
  }
  render() {
    let overflowX = this.overflow.x;
    let overflowY = this.overflow.y;

    let componentProps = {
      ref: this.scrollBarRef,
      options: {},
    };
    if (overflowX === "hidden") componentProps.options.suppressScrollX = true;
    if (overflowY === "hidden") componentProps.options.suppressScrollY = true;
    let styles = this.createDynamicStyles();
    return (
      <View
        onLayout={this.handleLayout}
        ref={this.ref}
        style={[
          styles.scrollView,
          !this.scrollEnabled && styles.disabled,
          this.props.style,
        ]}
        Component={PerfectScrollbar}
        ComponentRef={this.scrollbarRef}
        ComponentHtmlRefProp='containerRef'
        ComponentHtmlRefFunction={(ref) => {
          this.htmlRef = { current: ref };
          return this.htmlRef;
        }}
        ComponentProps={componentProps}
        onScroll={this.props.onScroll && this.handleScroll}
      >
        <View
          style={[styles.scrollViewContent, this.props.contentStyle]}
          htmlRef={this.contentHtmlRef}
          onLayout={this.handleContentSizeChange}
        >
          {this.props.children}
        </View>
      </View>
    );
  }
  createDynamicStyles() {
    /** @todo ScrollView render issue: when padding is added to scrollview, it behaves differantly than native, native overflows. */
    let height = this.props.height || null;
    if (this.props.autoSize) {
      height = "100%";
    }
    let overflowX = this.overflow.x;
    let overflowY = this.overflow.y;
    let scrollViewStyle = height
      ? {
          overflowY: overflowY,
          overflowX: overflowX,
          height: this.props.height,
          position: "relative",
        }
      : {
          overflowY: overflowY,
          overflowX: overflowX,
          position: "absolute",
          flex: 1,
          ...StyleSheet.absoluteFill,
        };

    if (this.props.showsHorizontalScrollIndicator === false) {
      scrollViewStyle["::-webkit-scrollbar"] = {
        width: 0,
        backgroundColor: "transparent",
      };
      scrollViewStyle["::-webkit-scrollbar *"] = {
        backgroundColor: "transparent",
      };
      scrollViewStyle.boxSizing = "content-box";
      // scrollViewStyle.height = 'auto';
      // scrollViewStyle.paddingBottom = 16;
    }
    return StyleSheet.create({
      scrollView: scrollViewStyle,
      disabled: {
        marginTop: `-${this.lastScrollTop}px`,
        position: "relative",
      },
      scrollViewContent: {
        flex: 1,
        // width: "200%"
        // overflow: "visible",
        // overflowX: "unset",
        // overflowY: "unset"
      },
    });
  }
}
