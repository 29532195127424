import React from "react";
import R14, { Button, View, Text, Colors, StyleSheet, CenteredForm } from "../core";
import FieldImage from "../components/FieldImage";

export default R14.connect(
  class ManualEntryScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRefreshPress = this.handleRefreshPress.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.manualEntryDomain = this.props.app.dm.manualEntry;
      this.keyMappings = {
        F6: "refresh",
      };
    }

    componentDidMount(){
      this.props.app.dm.userSession.unsubscribe();
    }

    async handleKeyDown(e) {
      let key = e.key || e.nativeEvent.key;
      if (!key) return false;
      if (!this.keyMappings[key]) return;
      e.preventDefault();
      switch (this.keyMappings[key]) {
        case "refresh":
          this.handleRefreshPress();
          break;
      }
    }

    async handleSubmit(manualEntryForm) {
      return this.handleRefreshPress;
    }
    async handleRefreshPress() {
      this.props.app.nav.to("manualEntryLoad");
      return false;
    }
    getKeyMapping(key) {
      return this.keyMappings[key] ? this.keyMappings[key] : null;
    }
    getKeyByMapping(mapping) {
      let ret = null;
      for (let i in this.keyMappings) {
        if (this.keyMappings[i] === mapping) {
          ret = i;
          break;
        }
      }
      return ret;
    }
    renderMessage() {
      return (
        this.props.message ||
        "No results at this time, please try to refresh in a few minutes."
      );
    }
    renderButtonTitle() {
      return this.props.buttonTitle || "Refresh";
    }
    renderControlsBottomRight() {
      let controls = [];
      controls.push([
        <Button
          title={this.renderButtonTitle()}
          tooltip={`${this.renderButtonTitle()} [${this.getKeyByMapping(
            "refresh"
          )}]`}
          key="refresh"
          style={styles.buttonRowButton}
          color={Colors.secondary}
          onPress={this.handleRefreshPress}
        />,
      ]);
      return controls;
    }
    render() {
      return (
        <CenteredForm
          onKeyDown={this.handleKeyDown}
          name="manualEntryRefreshForm"
          title={"Field Entry"}
          style={styles.centeredForm}
          onSubmit={this.handleSubmit}
          controlsBottomRight={this.renderControlsBottomRight()}
        >
          <View style={styles.noResults}>
            <Text>{this.renderMessage()}</Text>
          </View>
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  buttonRowButton: {
    // marginRight: 16,
  },
  centeredForm: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 480)
        return {
          width: "100%",
        };
    },
  },
  noResults: {
    marginBottom: 24,
  },
});
