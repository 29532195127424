import React from "react";
import { observer, inject } from "mobx-react";
import {
  set as mobxSet,
  configure as mobxConfig,
  // decorate,
  makeObservable,
  observable,
  action,
  toJS,
} from "mobx";
import R14Utils from "./R14Utils";
import R14Base from "./base/R14Base";

if (process.env.NODE_ENV === "development") {
  mobxConfig({
    enforceActions: "observed",
  });
}
export default class R14 extends R14Base {
  constructor() {
    super();
    this._metadata = {
      config: {},
      themeConfig: {},
      app: null,
      isRendering: false,
    };
    this.utils = new R14Utils();
  }
  static get utils() {
    return new R14Utils();
  }
  // Connects commpontent as app observer
  static connect(component) {
    return super.connect(component, R14.getInstance());
    // component.prototype._r14Render = component.prototype.render;
    // component.prototype.render = function(){
    // 	let r14 = R14.getInstance();
    // 	r14.rendering = true;
    // 	let ret = this._r14Render();
    // 	r14.rendering = false;
    // 	return ret;
    // }
    // return inject("app", "r14")(observer(component));
  }
  static connectForm(component) {
    return inject("app", "r14", "form")(observer(component));
  }
  static connectScroller(component) {
    return inject("app", "r14", "scroller")(observer(component));
  }
  static connectDraggableContainer(component) {
    return inject("app", "r14", "draggableContainer")(observer(component));
  }
  static getInstance() {
    if (global) {
      if (!global._r14) global._r14 = new R14();
      return global._r14;
    } else {
      if (!window._r14) window._r14 = new R14();
      return window._r14;
    }
  }
  static get api() {
    return R14.getInstance().app.api;
  }
  static get instance() {
    return R14.getInstance();
  }
  static get Actions() {
    return R14Actions;
  }
  static get Domain() {
    return R14Domain;
  }
  static get EntityDomain() {
    return R14EntityDomain;
  }
  static get Navigation() {
    return React.Component;
  }
  static get Theme() {
    return R14ThemeProvider;
  }
  static get DomainInstances() {
    return R14DomainInstances;
  }
  static listen(domain, props = {}) {
    domain.prototype.__r14MemberStateProps = props;
    // decorate(domain, props);
  }
  onExit() {
    // do nothing?
  }
  get app() {
    return this._metadata.app;
  }
  set app(app) {
    this._metadata.app = app;
    return this;
  }
  get isRendering() {
    return this._metadata.rendering;
  }
  set rendering(rendering) {
    this._metadata.rendering = rendering;
    return this;
  }
  get config() {
    return this.app.config;
  }
}

class R14Actions {
  constructor(routerPortal, domain) {
    this.result = this.rslt = new R14ActionsResultFactory();
    if (this.shouldActionLoad)
      this.shouldActionLoad = this.shouldActionLoad.bind(this);
    if (this.actionWillLoad)
      this.actionWillLoad = this.actionWillLoad.bind(this);
    this._routerPortal = routerPortal;
  }
  get model() {
    return R14.getInstance().app.mdl;
  }
  get mdl() {
    return this.model;
  }
  get domain() {
    return this.mdl.domain;
  }
  get dm() {
    return this.domain;
  }
  get ui() {
    return this.mdl.ui;
  }
  get navigation() {
    return R14.getInstance().app.navigation;
  }
  get nav() {
    return this.navigation;
  }
  get api() {
    return R14.getInstance().app.api;
  }
  get utilities() {
    return R14.getInstance().app.utils;
  }
  get utils() {
    return this.utilities;
  }
  get r14() {
    return R14.getInstance();
  }
}
class R14ActionsResultFactory {
  component(component) {
    return new R14ActionsComponentResult(component);
  }
}
class R14ActionsComponentResult {
  constructor(component) {
    this._metadata = {
      component: component,
    };
    this.props = {};
  }
  render() {
    let Component = this._metadata.component;
    return React.cloneElement(<Component />, this.props);
  }
}

export class R14Domain {
  __r14DomainState = null;
  constructor() {
    makeObservable(this, {
      __r14DomainState: observable,
      setState: action,
    });

    // this.__createArrProxy = this.__createArrProxy.bind(this);
    // this.__stateArrPush = this.__stateArrPush.bind(this);
    // this.__stateArrRemove = this.__stateArrRemove.bind(this);
    this.isUpdatingState = false;
    this.__r14DomainState = null;
  }
  get model() {
    return R14.getInstance().app.mdl;
  }
  get mdl() {
    return this.model;
  }
  get domain() {
    return this.mdl.domain;
  }
  get dm() {
    return this.domain;
  }
  get entity() {
    return this.mdl.entity;
  }
  get ent() {
    return this.entity;
  }
  get database() {
    return this.mdl.database;
  }
  get db() {
    return this.database;
  }
  get query() {
    return this.db.qry;
  }
  get qry() {
    return this.query;
  }
  get ui() {
    return this.mdl.ui;
  }
  get navigation() {
    return R14.getInstance().app.navigation;
  }
  get nav() {
    return this.navigation;
  }
  get api() {
    return R14.getInstance().app.api;
  }
  get utilities() {
    return R14.getInstance().app.utils;
  }
  get utils() {
    return this.utilities;
  }
  get api() {
    return R14.getInstance().app.api;
  }
  get r14() {
    return R14.getInstance();
  }
  setState(values) {
    this.isUpdatingState = true;
    let memberStateProps = this.__r14MemberStateProps
      ? this.__r14MemberStateProps
      : {};
    for (let name in values) {
      if (name in memberStateProps) this[name] = values[name];
      else if (this.__r14DomainState) {
        this.__r14DomainState[name] = values[name];
      }
    }
    this.isUpdatingState = false;
  }
  get state() {
    return this.getState();
  }
  getState() {
    return new Proxy(this, {
      get: function (obj, prop) {
        let r14 = R14.getInstance();
        if (prop in obj.__r14DomainState) {
          if (r14.isRendering) return obj.__r14DomainState[prop];
          else return toJS(obj.__r14DomainState[prop]);
        } else return null;
      },
    });
  }
  set state(state) {
    if (this.__r14DomainState) throw "State has already been set.";
    // this.__r14DomainState = observable(state);
    this.__r14DomainState = state;
  }
}
// decorate(R14Domain, {
//   setState: action,
// });

export class R14DomainInstances extends R14Domain {
  constructor() {
    super();
    this._instances = {};
    return new Proxy(() => {}, {
      get: (obj, prop) => {
        if (this[prop]) return this[prop];
        if (this.exists(prop)) return this.getInstance(prop);
        else return null;
      },
      set: (obj, prop, value) => {
        this[prop] = value;
        return true;
      },
      apply: (obj, context, args) => {
        return this.getInstance(args[0]);
      },
    });
  }
  addInstance(key, instance) {
    this._instances[key] = instance;
  }
  removeInstance(key) {
    if (this.exists(key)) delete this._instances[key];
    else return null;
  }
  getInstance(key) {
    if (this.exists(key)) return this._instances[key];
    else return null;
  }
  exists(key) {
    return this._instances[key] ? true : false;
  }
  forEach(fn) {
    for (let i in this._instances) {
      fn(this._instances[i], i);
    }
  }
  sort(fn){
    return this.toArray().sort(fn);
  }
  map(fn) {
    let ret = [];
    for (let i in this._instances) {
      ret.push(fn(this._instances[i], i));
    }
    return ret;
  }
  filter(fn) {
    let ret = [];
    for (let i in this._instances) {
      let res = fn(this._instances[i], i);
      if (res === true) ret.push(this._instances[i]);
    }
    return ret;
  }
  toArray() {
    return this.filter(() => true);
  }
  getInstances() {
    return this._instances;
  }
  get first() {
    let ret = null;
    for (let i in this._instances) {
      ret = this._instances[i];
      break;
    }
    console.log("RETURN FIRLST", this._instances, ret);
    return ret;
  }
}

class R14EntityDomain extends R14Domain {
  constructor(config) {
    super();
    if (!config) throw new Error("No entity config found.");
    if (!config.name) throw new Error("Entity name required in config");
    this._entityConfig = config;
  }
  formatEntityName(options = { capitalize: false, plural: false }) {
    let name = options.plural ? this.entityPluralName : this.entityName;
    if (options.capitalize) name = this.utils.str.capitalize(name);
    return name;
  }
  get idField() {
    return this._entityConfig.idField || "uid";
  }
  get entityName() {
    return this._entityConfig.name;
  }
  get entityPluralName() {
    return this._entityConfig.pluralName || `${this.entityName}s`;
  }
  get capitalizedEntityName() {
    return this.utils.str.capitalize(this.entityName);
  }
  fieldsToString(fields) {
    if (!fields) return null;
    return typeof fields === "string"
      ? fields
      : this.utils.gql.fieldsToString(fields);
  }
  async find(fields, options = null) {
    let fieldsStr = this.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Entity Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    if (options.clientUid !== false)
      options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query Find${this.formatEntityName({
        capitalize: true,
        plural: true,
      })}($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ${this.formatEntityName(
        {
          capitalize: true,
        }
      )}Filter) {
        ${this.formatEntityName({
          plural: true,
        })}(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data[
      this.formatEntityName({
        plural: true,
      })
    ];
  }
  async fetchSelections(filters, options = {}) {
    let filter = { ...filters };
    let fieldMap = options.fieldMap || {
      value: "uid",
      label: "name",
    };
    let sort = options.sort || [
      {
        field: "name",
        order: "ASC",
      },
    ];
    let fields = Object.keys(fieldMap).map((key) => fieldMap[key]);
    if (filters.search && typeof filters.search !== "object") {
      filter.search = { like: `%${filters.search}%` };
    }
    let res = await this.find(fields, {
      page: 1,
      resultsPerPage: options.resultsPerPage || 25,
      filter,
      totalCount: false,
      sort,
      clientUid: false,
    });
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => {
            let ret = {
              label: val[fieldMap.label],
              value: val[fieldMap.value],
            };
            return ret;
          })
        : [];
    return ret;
  }
  async get(uid, fields) {
    let fieldsStr = this.fieldsToString(fields);
    if (!fieldsStr) throw new Error("Entity Domain Get Error: No fields found");
    let res = await this.api.qry(
      `
      query Get${this.formatEntityName({
        capitalize: true,
      })}($uid: ID!) {
        ${this.formatEntityName()}(uid: $uid){
          ${fieldsStr}
        }
      }`,
      {
        uid: uid,
      }
    );
    if(res.errors && res.errors.length) throw new Error(res.errors[0].message);
    return res.data[this.formatEntityName()];
  }
  async save(values, fields = null) {
    return values[this.idField]
      ? await this.update(values, fields)
      : await this.create(values, fields);
  }
  async create(values, fields = null) {
    let fieldsStr = this.fieldsToString(fields);
    if (!fieldsStr) fieldsStr = "uid";
    let res = await this.api.mutate(
      `
      mutation Create${this.formatEntityName({
        capitalize: true,
      })}($input: Create${this.formatEntityName({
        capitalize: true,
      })}Input!) {
        create${this.formatEntityName({
          capitalize: true,
        })}(input: $input){
          ${this.formatEntityName()} {
            ${fieldsStr}
          }
        }
      }`,
      {
        input: this.parseSubmitValues(values),
      }
    );
    if(res.errors && res.errors.length) throw new Error(res.errors[0].message);
    return true;
  }
  async update(values, fields = null) {
    let fieldsStr = this.fieldsToString(fields);
    if (!fieldsStr) fieldsStr = "uid";
    let res = await this.api.mutate(
      `
      mutation Update${this.formatEntityName({
        capitalize: true,
      })}($input: Update${this.formatEntityName({
        capitalize: true,
      })}Input!) {
        update${this.formatEntityName({
          capitalize: true,
        })}(input: $input){
          ${this.formatEntityName()} {
            ${fieldsStr}
          }
        }
      }`,
      {
        input: this.parseSubmitValues(values),
      }
    );
    if(res.errors && res.errors.length) throw new Error(res.errors[0].message);
    return true;
  }
  async delete(uid, fields = null) {
    let fieldsStr = this.fieldsToString(fields);
    if (!fieldsStr) fieldsStr = "uid";
    let res = await this.api.mutate(
      `
      mutation Delete${this.formatEntityName({
        capitalize: true,
      })}($uid: ID!) {
        delete${this.formatEntityName({
          capitalize: true,
        })}(uid: $uid){
          ${this.formatEntityName()} {
            ${fieldsStr}
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    if(res.errors && res.errors.length) throw new Error(res.errors[0].message);
    return true;
  }
  async fetchEditFormData(uid = null) {
    return await this.get();
  }
  parseSubmitValues(values) {
    return values;
  }
}

export class R14ThemeProvider {
  constructor(config) {
    R14.getInstance()._metadata.themeConfig = config;
  }
}
