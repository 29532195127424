import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import Navigation from '../Navigation';
import RootComponents from '../RootComponents';

export default class AppBase extends React.Component {
  render() {
    return (
        <BrowserRouter>
          <Route component={Navigation} />
          <RootComponents />
        </BrowserRouter>
    );
  }
}