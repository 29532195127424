import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import RadioButton from "./RadioButton";
import View from "./View";
import Text from "./Text";
import InputField from "./InputField";
import StyleSheet from "./StyleSheet";
import Touchable from "./Touchable";
import Scroller from "./Scroller";
import { Colors } from "./Theme";

export default R14.connectForm(
  /**
   * A hidden form field field component
   */
  class RadioButtonField extends React.Component {
    static propTypes = {
      /** Label for the radioButton. */
      label: PropTypes.string,
      /** Name for the radioButton field. */
      name: PropTypes.string.isRequired,
      /** Value of the radioButton. */
      value: PropTypes.bool,
      /** Array of label / value objects. */
      items: PropTypes.array,
      /** Handler to be called when the radioButton groups value is changed  */
      onValueChange: PropTypes.func,
      /** If true, disable the radioButton */
      disabled: PropTypes.bool,
    };
    static defaultProps = {
      items: [],
    };
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleItemLabelPress = this.handleItemLabelPress.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.state = {
        activeItemIdx: null,
        scroller: false,
      };
      if (this.props.onValueChange)
        this.elmt.addEventListener("change", () => {
          this.props.onValueChange(this.elmt.value, this.elmt);
        });
    }
    handleValueChange(value) {
      this.elmt.setValue(value);
      // if (this.props.onValueChange) this.props.onValueChange(value, this.elmt);
      this.elmt.form.triggerValueChange(value, this.elmt);
    }
    handleKeyDown(e) {
      if (this.props.handleKeyDown) this.props.handleKeyDown(e);
      if (!e.nativeEvent || !e.nativeEvent.key) return false;
      let items = [...this.elmt.items];
      let activeItemIdx = null;
      let nextItemIdx = null;
      for (let idx in items) {
        if (this.elmt.value === items[idx].value) {
          activeItemIdx = parseInt(idx);
        }
      }
      if (!["ArrowDown", "ArrowUp"].includes(e.nativeEvent.key)) return false;
      if (activeItemIdx !== null) {
        switch (e.nativeEvent.key) {
          case "ArrowDown":
            nextItemIdx = activeItemIdx + 1;
            if (nextItemIdx > items.length - 1) nextItemIdx = 0;
            break;
          case "ArrowUp":
            nextItemIdx = activeItemIdx - 1;
            if (nextItemIdx < 0) nextItemIdx = items.length - 1;
            break;
        }
      } else nextItemIdx = 0;
      let item = items[nextItemIdx];
      if (item) this.handleItemLabelPress(item);
    }
    handleFocus(item) {
      this.elmt.setFocus(true, { item });
      if (this.props.onFocus) this.props.onFocus(this.elmt, item);
    }
    handleBlur(item) {
      this.elmt.setBlur(true, { item });
      if (this.props.onBlur) this.props.onBlur(this.elmt, item);
    }
    handleItemLabelPress(item) {
      this.elmt.setFocus(true, { item });
      this.handleValueChange(
        item.value !== this.elmt.value ? item.value : null
      );
    }
    handleLayout(event) {
      if (!this.props.maxContentHeight) return false;
      this.setState({
        scroller:
          event.nativeEvent.layout.height >= this.props.maxContentHeight,
      });
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    renderRadioButtonGroup() {
      let radioButtons = this.elmt.items.map((item, i) => {
        // autofocus can be either boolean or value
        let idx = parseInt(i);
        let autoFocus =
          (this.props.autoFocus === true && idx === 0) ||
          this.props.autoFocus === item.value;

        return (
          <View
            key={item.value}
            style={[
              styles.radioButtonRow,
              this.elmt.isItemFocused(item) && styles.radioButtonRowFocus,
            ]}
          >
            <RadioButton
              name={item.value}
              // value={true}
              elementRef={this.elmt.getItemRef(item)}
              value={this.elmt.value === item.value}
              onValueChange={(value) =>
                this.handleValueChange(value ? item.value : null)
              }
              onKeyDown={this.handleKeyDown}
              onFocus={(e) => this.handleFocus(item)}
              onBlur={(e) => this.handleBlur(item)}
              tabIndex={idx === 0 ? 0 : -1}
              autoFocus={autoFocus}
              style={styles.radioButton}
            />
            <Touchable
              style={styles.radioButtonLabel}
              onPress={() => this.handleItemLabelPress(item)}
            >
              <Text style={styles.radioButtonLabelText}>{item.label}</Text>
            </Touchable>
          </View>
        );
      });
      let ret = null;
      if (this.state.scroller) {
        ret = (
          <Scroller
            name='radioButtonGroupScroller'
            style={[styles.scroller]}
            direction='vertical'
            height={this.props.maxContentHeight}
            //onScroll={this.handleScroll}
          >
            <Scroller.Item
              name='radioButtonGroupScrollerContent'
              style={styles.scrollerItem}
              indicatorStyle={styles.scrollerItemIndicator}
            >
              {radioButtons}
            </Scroller.Item>
          </Scroller>
        );
      } else
        ret = (
          <View
            style={styles.radioButtonFieldWrapper}
            onLayout={this.props.maxContentHeight && this.handleLayout}
          >
            <View style={styles.radioButtonGroup}>{radioButtons}</View>
          </View>
        );
      return ret;
    }
    render() {
      return (
        <InputField
          // {...this.props}
          label={this.props.label}
          staticLabel
          style={this.props.style}
          element={this.elmt}
          InputComponent={this.renderRadioButtonGroup()}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  radioButtonFieldWrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...StyleSheet.padding(12, 0, 12, 0),
    // height: 52,
  },
  labelText: {
    lineHeight: 14,
    // pointerEvents: "none",
    fontWeight: "500",
    paddingRight: 12,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  radioButton: {
    ...StyleSheet.padding(1, 0, 0, 0),
  },
  radioButtonLabel: {
    flex: 1,
    ...StyleSheet.padding(8, 8, 8, 16),
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  radioButtonLabelText: {
    lineHeight: 24,
    fontSize: 16,
  },
  radioButtonRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    minHeight: 40,
    // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    // marginBottom: 4,
    ...StyleSheet.margin(0, 8, 0, 8),
    borderRadius: 4,
  },
  radioButtonRowFocus: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.02),
  },
  radioButtonGroup: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  scroller: {
    //height: 208,
    ...StyleSheet.margin(8, 0, 8, 0),
  },
  scrollerItem: {
    ...StyleSheet.padding(4, 0, 4, 0),
  },
  scrollerItemIndicator: {
    height: 0,
    width: 0,
  },
});
