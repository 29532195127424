import React from "react";
import PropTypes from "prop-types";
import View from "./View";
import Surface from "./Surface";
import ChartBase from "./base/ChartBase";
import Card from "./Card";
import StyleSheet, { css } from "./StyleSheet";
// import R14 from "./R14";

/** A chart component */
export default class Chart extends React.Component {
  static propTypes = {
    /** Optional title for the chart. */
    name: PropTypes.string.isRequired,
    /** Optional title for the chart. */
    // title: PropTypes.string,
    // /** Controls such as buttons, icon buttons, etc... Placed at the right of the title.*/
    // titleControlsRight: PropTypes.oneOfType([
    //   PropTypes.arrayOf(PropTypes.node),
    //   PropTypes.node
    // ]),
    // /** The elevation (z index shadow effect) of the surface. */
    // elevation: PropTypes.number,
    // /** The total width of the component */
    // width: PropTypes.number,
    // /** The minimum width of the component. */
    // minWidth: PropTypes.number
  };
  render() {
    return <View style={this.props.style}><ChartBase {...this.props} /></View>;
  }
}
