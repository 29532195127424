import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import Touchable from "./Touchable";
import View from "./View";
import StyleSheet from "./StyleSheet";
export default R14.connectDraggableContainer(
  class DraggableView extends React.Component {
    static propTypes = {
      /** The name for the view */
      name: PropTypes.string.isRequired,
      /** The draggable ui domain instance */
      draggableContainer: PropTypes.func.isRequired,
      /** The top offset in pixels from the container */
      offsetTop: PropTypes.number,
      /** The left offset in pixels from the container */
      offsetLeft: PropTypes.number,
      /** Disables draggable when given true */
      disabled: PropTypes.bool,
    };
    static defaultProps = {
      offsetTop: 0,
      offsetLeft: 0,
      disabled: false,
    };
    constructor(props) {
      super(props);
      this.draggableView = this.props.draggableContainer.addView(this.props);
      this.handlePressMove = this.handlePressMove.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.handlePressIn = this.handlePressIn.bind(this);
      this.handlePressOut = this.handlePressOut.bind(this);
      this.handlePressCancel = this.handlePressCancel.bind(this);
      this.ref = React.createRef();
      this.state = {
        dragging: false,
      };
    }
    componentWillUnmount() {
      this.draggableView.remove();
    }
    handlePressMove(event) {
      this.draggableView.handlePressMove(event);
    }
    handlePressOut(event) {
      this.draggableView.handlePressOut(event);
    }
    handlePressIn(event) {
      let disabled = this.props.disabled;
      if (this.props.onPressIn) {
        let pressInDisabled = this.props.onPressIn(event) === false;
        if (!disabled && pressInDisabled) disabled = true;
      }
      event.preventDefault();
      !disabled && this.draggableView.handlePressIn(event);
    }
    handlePressCancel(event) {
      this.draggableView.handlePressCancel(event);
    }
    handleLayout(event) {
      // this.measure((x, y, width, height, pageX, pageY) =>
      //   this.draggableView.handleMeasure(x, y, width, height, pageX, pageY)
      // );
      this.draggableView.handleLayout(event);
    }
    // measure(callback) {
    //   this.ref.current.measure(callback);
    // }
    createDynamicStyles() {
      let dynamicStyles = {
        draggable: {
          position: "absolute",
          top: this.props.offsetTop,
          left: this.props.offsetLeft,
          height:
            this.draggableView.state.resizeHeight !== null
              ? this.draggableView.state.resizeHeight
              : this.props.height || null,
          width:
            this.draggableView.state.resizeWidth !== null
              ? this.draggableView.state.resizeWidth
              : this.props.width || null,
          zIndex: this.draggableView.active ? 1 : 0,
          opacity: this.draggableView.active ? 0.8 : 1,
          transform: [
            { translateX: this.draggableView.state.dragX },
            { translateY: this.draggableView.state.dragY },
          ],
          cursor: this.props.disabled
            ? null
            : this.draggableView.active
            ? "grabbing"
            : "grab",
        },
      };
      if (this.draggableView.active && this.draggableView.styleOnDrag) {
        dynamicStyles.styleOnDrag = this.draggableView.styleOnDrag;
      }
      return StyleSheet.create(dynamicStyles);
    }
    getDelta(event) {
      let left = event.pageX;
      let top = event.pageY;
      let delta = {
        left: left - this.previousLeft,
        top: top - this.previousTop,
      };
      this.previousLeft = left;
      this.previousTop = top;
      return delta;
    }
    renderResizableTargets() {
      return [
        <View key='resizeLeftTarget' style={styles.resizeLeftTarget} />,
        <View key='resizeTopTarget' style={styles.resizeTopTarget} />,
        <View key='resizeBottomTarget' style={styles.resizeBottomTarget} />,
        <View key='resizeRightTarget' style={styles.resizeRightTarget} />,
        <View key='resizeTopLeftTarget' style={styles.resizeTopLeftTarget} />,
        <View key='resizeTopRightTarget' style={styles.resizeTopRightTarget} />,
        <View
          key='resizeBottomLeftTarget'
          style={styles.resizeBottomLeftTarget}
        />,
        <View
          key='resizeBottomRightTarget'
          style={styles.resizeBottomRightTarget}
        />,
      ];
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      return (
        <Touchable
          ref={this.ref}
          onLayout={this.handleLayout}
          onPressOut={this.props.disabled ? null : this.handlePressOut}
          onPressIn={this.handlePressIn}
          onPress={this.props.onPress || null}
          // onPressMove={this.handlePressMove}
          // onPressCancel={this.handlePressCancel}
          feedback={false}
          style={[
            dynamicStyles.draggable,
            this.props.style,
            this.draggableView.active && this.props.activeStyle,
            this.draggableView.active && dynamicStyles.styleOnDrag,
          ]}
        >
          {this.props.children}
          {/* {this.draggableView.active && <View style={styles.moveTarget} />} */}
          {this.props.resizable && this.renderResizableTargets()}
        </Touchable>
      );
    }
  }
);
const styles = StyleSheet.create({
  resizeLeftTarget: {
    position: "absolute",
    cursor: "w-resize",
    left: 0,
    top: 4,
    bottom: 4,
    width: 4,
  },
  resizeRightTarget: {
    position: "absolute",
    cursor: "e-resize",
    right: 0,
    top: 4,
    bottom: 4,
    width: 4,
  },
  resizeTopTarget: {
    position: "absolute",
    cursor: "n-resize",
    right: 4,
    left: 4,
    top: 0,
    height: 4,
  },
  resizeBottomTarget: {
    position: "absolute",
    cursor: "s-resize",
    right: 4,
    left: 4,
    bottom: 0,
    height: 4,
  },
  resizeTopLeftTarget: {
    position: "absolute",
    cursor: "nw-resize",
    left: 0,
    top: 0,
    width: 4,
    height: 4,
  },
  resizeTopRightTarget: {
    position: "absolute",
    cursor: "ne-resize",
    right: 0,
    top: 0,
    width: 4,
    height: 4,
  },
  resizeBottomLeftTarget: {
    position: "absolute",
    cursor: "sw-resize",
    left: 0,
    bottom: 0,
    width: 4,
    height: 4,
  },
  resizeBottomRightTarget: {
    position: "absolute",
    cursor: "se-resize",
    right: 0,
    bottom: 0,
    width: 4,
    height: 4,
  },
});
