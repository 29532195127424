import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import View from "./View";
import AnimatedView from "./AnimatedView";
import IconButton from "./IconButton";
import Icon from "./Icon";
import Touchable from "./Touchable";
import { Colors } from "./Theme";
import StyleSheet from "./StyleSheet";
import Button from "./Button";
import Text from "./Text";
/**
 * Component for grouping form fields
 */
export default R14.connect(
  class SnackBar extends React.Component {
    static propTypes = {
      /** The unique name for the data table, the root navigator uses 'default'.  */
      name: PropTypes.string.isRequired,
      // /** The title of the shackBar  */
      // title: PropTypes.string,
      /** The message of the shackBar */
      message: PropTypes.string.isRequired,
      /** The text to display for the right action text button  */
      actionText: PropTypes.string,
      /** The action to trigger when the action text button is pressed  */
      action: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.oneOf(["dismiss"]),
      ]),
      /** The visible prop determines whether to show the message or not  */
      visible: PropTypes.bool.isRequired,
      /** The shackBar variant to use */
      variant: PropTypes.oneOf(["info", "error", "success"]),
      /** The amount of time in seconds before the shackBar self dismisses */
      dismissTimeout: PropTypes.number,
      /** The function to be called when the shackBar is pressed */
      onPress: PropTypes.func,
      /** Whether the snackBar should dismiss when pressed */
      dismissOnPress: PropTypes.bool,
      /** The function to be called when the shackBar requests to hide */
      onRequestDismiss: PropTypes.func,
      /** The bottom margin of the snackBar. Useful for screen with FAB */
      marginBottom: PropTypes.number,
    };
    static defaultProps = {
      variant: "info",
      dismissTimeout: 4,
      dismissOnPress: true,
      action: "dismiss",
    };
    constructor(props) {
      super(props);
      this.handleRequestDismiss = this.handleRequestDismiss.bind(this);
      this.handleActionButtonPress = this.handleActionButtonPress.bind(this);
      this._dismissTimeout = null;
    }
    componentDidUpdate(prevProps) {
      if (this.props.dismissTimeout) {
        if (this.props.visible && !prevProps.visible)
          this.createDismissTimeout();
        else if (!this.props.visible && prevProps.visible)
          this.clearDismissTimeout();
      }
    }
    componentWillUnmount() {
      this.clearDismissTimeout();
    }

    handleRequestDismiss() {
      this.props.onRequestDismiss && this.props.onRequestDismiss();
    }
    handleActionButtonPress() {
      if (typeof this.props.action === "string") {
        switch (this.props.action) {
          case "dismiss":
            this.handleRequestDismiss();
            break;
          default:
          // Do NOthing
        }
      } else this.props.action && this.props.action();
    }
    clearDismissTimeout() {
      this._dismissTimeout && clearTimeout(this._dismissTimeout);
    }

    createDismissTimeout() {
      this._dismissTimeout = setTimeout(() => {
        this.handleRequestDismiss();
        this._dismissTimeout = null;
      }, this.props.dismissTimeout * 1000);
    }
    createDynamicStyles() {
      let ret = {};
      if (this.props.marginBottom) {
        ret.snackBar = { marginBottom: this.props.marginBottom };
      }
      return Object.keys(ret).length ? StyleSheet.create(ret) : {};
    }
    render() {
      // let iconName = "help";
      let components = [];

      let iconName = null;
      let variantIcon = null;
      let onBackgroundColor = Colors.secondary;
      let dynamicStyles = this.createDynamicStyles();

      if (this.props.variantIcon === true) {
        throw new Error();
      }

      switch (this.props.variant) {
        case "info":
          variantIcon = "help";
          break;
        case "error":
          variantIcon = "error";
          onBackgroundColor = Colors.onError.default("#FFFFFF");
          break;
        case "success":
          variantIcon = "checkCircle";
          onBackgroundColor = Colors.onSuccess.default("#FFFFFF");
          break;
        default:
        // Do nothing
      }

      if (this.props.icon)
        iconName =
          this.props.icon === true ? variantIcon || null : this.props.icon;

      this.props.icon &&
        components.push(
          <View
            key='icon'
            color={this.props.iconColor || onBackgroundColor}
            style={[styles.icon]}
          >
            <Icon size='large' name={iconName} />
          </View>
        );

      let snackBarStyles = [
        styles.snackBar,
        styles[
          `snackBarVariant${R14.utils.str.capitalize(this.props.variant)}`
        ],
        dynamicStyles.snackBar,
        this.props.style
      ];

      let contentStyles = [
        styles.content,
        styles[`contentVariant${R14.utils.str.capitalize(this.props.variant)}`],
      ];

      components.push(
        <View key='message' style={[styles.message]}>
          <Text style={[styles.messageText]}>{this.props.message}</Text>
        </View>
      );
      // if (!this.props.dismissOnPress)
      //   components.push(
      //     <View key='closeIcon' style={[styles.closeIcon]}>
      //       <IconButton
      //         icon='close'
      //         size='small'
      //         onPress={this.handleRequestDismiss}
      //       />
      //     </View>
      //   );

      let contentComponents = [];

      if (this.props.dismissOnPress) {
        contentComponents.push(
          <Touchable
            key='content'
            onPress={this.handleRequestDismiss}
            style={contentStyles}
          >
            {components}
          </Touchable>
        );
      } else {
        contentComponents.push(
          <View key='content' style={contentStyles}>
            {components}
          </View>
        );
      }

      if (this.props.actionText) {
        contentComponents.push(
          <Button
            key='actionButton'
            variant='text'
            color={this.props.actionButtonColor || onBackgroundColor}
            style={[styles.actionButton]}
            title={this.props.actionText}
            onPress={this.handleActionButtonPress}
          />
        );
      }

      return (
        <AnimatedView
          style={snackBarStyles}
          in={this.props.visible}
          // animateOnMount
          unmountOnHide
          enter={{
            opacity: 1,
            transform: [{ scale: 1 }],
          }}
          exit={{
            opacity: 0,
            transform: [{ scale: 0.75 }],
          }}
          duration={100}
        >
          {contentComponents}
        </AnimatedView>
      );
    }
  }
);

const styles = StyleSheet.create({
  snackBar: {
    position: "absolute",
    bottom: 8,
    // left: 0,
    // right: 8,
    ...StyleSheet.margin(16, 16, 12, 16),
    zIndex: 10,
    backgroundColor: "#2C2C2C",
    opacity: 0.95,
    flex: 1,
    flexDirection: "row",
    elevation: 8,
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    minHeight: 48,
    width: "auto",
    ...StyleSheet.padding(6, 16, 6, 16),
    screen: ({ width }) =>
      width <= 480 && {
        left: 0,
        right: 0,
      },
  },
  icon: {
    opacity: 0.8,
    ...StyleSheet.margin(0, 0, 0, -8),
    paddingRight: 8,
  },
  closeIcon: {
    ...StyleSheet.margin(-8, -12, -12, 0),
  },
  title: {
    paddingBottom: 4,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 500,
  },
  actionButton: {
    flexGrow: 0,
    flexBasis: 0,
    opacity: 0.8,
    ...StyleSheet.margin(-1, -4, -1, 8),
  },
  message: {
    ...StyleSheet.padding(6, 0, 6, 0),
  },
  messageText: {
    // fontSize: 14,
    color: "#FFFFFF",
  },
  content: {
    flex: 0,
    flexDirection: "row",
  },
  snackBarVariantInfo: {
    backgroundColor: "#2C2C2C",
  },
  textVariantInfo: {
    color: "#FFFFFF",
  },
  snackBarVariantError: {
    backgroundColor: Colors.error.default("#e53935"),
  },
  textVariantError: {
    color: Colors.onError.default("#FFFFFF"),
  },
  snackBarVariantSuccess: {
    backgroundColor: Colors.success.default("#43a047"),
  },
  textVariantInfoSuccess: {
    color: Colors.onSuccess.default("#FFFFFF"),
  },
});
