import React from "react";
import PropTypes from "prop-types";
import R14, {
  TextInput,
  StyleSheet,
  Colors,
  AnimatedView,
  View,
  Text,
  Scroller,
  Image,
  Surface,
  Chip,
  ActivityIndicator,
  FadeView,
} from "../core";
import TimeDurationLabel from "./TimeDurationLabel";
export default R14.connect(
  class DocumentSetFieldScroller extends React.Component {
    static propTypes = {};
    constructor(props) {
      super(props);
      this.handlePress = this.handlePress.bind(this);
    }
    handlePress({ annotationUuid }) {
      // R14.instance.app.ui.progressIndicator.show();
      this.props.documentSet.navToAnnotation(annotationUuid);
      // R14.instance.app.ui.progressIndicator.hide({ timeout: 500 });
    }
    getIndicatorStyle(annotation, active = false) {
      let activeStr = active ? "Active" : "";
      let styleName =
        annotation.metadata.state === this.props.app.dm.manualEntry.STATE_REVIEW
          ? annotation.metadata.reject
            ? `scrollerItemIndicator${activeStr}Reject`
            : annotation.metadata.changed
            ? `scrollerItemIndicator${activeStr}Changed`
            : `scrollerItemIndicator${activeStr}Accept`
          : `scrollerItemIndicator${activeStr}`;
      return styles[styleName] || null;
    }
    getTextStyle(annotation, active = false) {
      return annotation.metadata.state ===
        this.props.app.dm.manualEntry.STATE_COMPLETE
        ? styles.scrollerItemTextComplete
        : null;
    }
    renderItems() {
      return this.props.documentSet
        .findAnnotations({
          sortBy: ["page", "layout"],
          type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD,
        })
        .map((annotation) => (
          <Scroller.Item
            key={annotation.uuid}
            name={annotation.uuid}
            style={styles.scrollerItem}
            activeStyle={styles.scrollerItemActive}
            onPress={this.handlePress}
            annotationUuid={annotation.uuid}
            indicatorPosition='left'
            indicatorStyle={this.getIndicatorStyle(annotation)}
            indicatorActiveStyle={this.getIndicatorStyle(annotation, true)}
            // active={
            //   this.props.documentSet.currentAnnotation &&
            //   this.props.documentSet.currentAnnotation.type ===
            //     annotation.type &&
            //   this.props.documentSet.currentAnnotation.key === annotation.key
            // }
          >
            <Chip
              size='small'
              key='pageNumber'
              label={`${annotation.pageNumber}`}
              labelTextStyle={styles.chipLabelText}
              style={styles.pageNumberChip}
            />
            <Text
              ellipsisMode='tail'
              numberOfLines={1}
              style={this.getTextStyle(annotation)}
            >
              {annotation.label || annotation.name}
            </Text>
            {!annotation.valueExists && (
              <Chip
                size='small'
                key='blank'
                label='blank'
                labelTextStyle={styles.chipLabelText}
                style={styles.chip}
              />
            )}
          </Scroller.Item>
        ));
    }
    render() {
      return (
        <View style={styles.documentSetFieldScroller}>
          <Scroller
            name='annotationScroller'
            activeItemName={
              this.props.documentSet.currentAnnotation
                ? this.props.documentSet.currentAnnotation.uuid
                : null
            }
            style={styles.scroller}
            direction='vertical'
          >
            {this.renderItems()}
          </Scroller>
          {this.props.documentSet.state.annotationsLoading && (
            <ActivityIndicator
              size='large'
              containerStyle={styles.activityIndicator}
            />
          )}
        </View>
      );
    }
    createDynamicStyles() {
      let dynamicStyles = {};
      this.props.documentSet.pages.forEach((page) => {
        let { width } = this.calculateThumbnailSize(page.image);
        dynamicStyles[`${this.createPageImageKey(page)}ScrollerItem`] = {
          height: this.thumbnailHeight,
          width,
        };
      });
      return StyleSheet.create(dynamicStyles);
    }
  }
);

const styles = StyleSheet.create({
  documentSetFieldScroller: {
    position: "relative",
    flex: 1,
    flexDirection: "column",
  },
  scroller: {
    width: 360,
  },
  activityIndicator: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
  },
  chip: {
    ...StyleSheet.margin(0, 0, 0, "auto"),
    height: 24,
    //marginLeft: "auto"
  },
  chipLabelText: {
    fontSize: 11,
    // fontWeight: "500"
  },
  pageNumberChip: {
    height: 24,
    ...StyleSheet.margin(0, 8, 0, 0),
    ...StyleSheet.padding(0),
  },
  scrollerItem: {
    width: "100%",
    height: 48,
    lineHeight: 48,
    // width: "auto",
    flex: 0,
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 0,
    ...StyleSheet.padding(0, 16, 0, 16),
    ...StyleSheet.margin(0, 0, 4, 0),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.01),
  },
  scrollerItemComplete: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0),
  },
  scrollerItemTextComplete: {
    color: StyleSheet.color(Colors.onSurface).rgba(0.6),
  },
  scrollerItemActive: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.04),
  },
  scrollerItemIndicatorActive: {
    backgroundColor: StyleSheet.color(Colors.activeQueueItem).rgba(0.8),
  },
  scrollerItemIndicatorReject: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
  scrollerItemIndicatorActiveReject: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.8),
  },
  scrollerItemIndicatorAccept: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.4),
  },
  scrollerItemIndicatorActiveAccept: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.8),
  },
  scrollerItemIndicatorChanged: {
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.4),
  },
  scrollerItemIndicatorActiveChanged: {
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.8),
  },
  // characterFieldFocused: {
  //   borderColor: StyleSheet.color(Colors.onSurface).rgba(0.8),
  // },
  // characterFieldChanged: {
  //   borderColor: StyleSheet.color("#4caf50").rgba(0.6),
  // },
  // characterFieldFocus: {
  //   borderColor: StyleSheet.color(Colors.primary).rgba(0.8),
  //   elevation: 6,
  // },
  // characterFieldReject: {
  //   borderColor: StyleSheet.color(Colors.secondary).rgba(0.8),
  // },
  // characterFieldEdit: {
  //   borderColor: StyleSheet.color(Colors.primaryLight).rgba(0.8),
  // },
});
